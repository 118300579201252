import React, { useState } from 'react';
import {
  PanelCardButton,
  PanelCardContainer,
  PanelCardControls,
  PanelCardHeader,
  PanelCardItem,
  PanelCardItemLink,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardTitle,
} from '../../SidePanel.elements';
// import ReviewedCard from './ReviewedCard';
import { PanelCardStandardProps } from '../types';
import { useReviewCardState, useVerificationDocuments } from './hooks';
import { DefaultNotes } from './defaultNotes';
import { AttachedFileViewer } from '.';
import { VerificationDocument } from '../../../../../domain/verificationDocument';
import { nanoid } from 'nanoid';
import { IDINUserInfo } from '../../../../../domain/signicat';
import { ReviewModal } from '..';
import { OptionsPropType } from '@atlaskit/radio/types';

interface ReviewRepresentativeProps extends PanelCardStandardProps {
  verificationDocuments: VerificationDocument[];
  isBusinessAccount: boolean | 'true' | 'false';
  IDINUserInfo: IDINUserInfo | null;
}

const ReviewRepresentative: React.FC<ReviewRepresentativeProps> = ({
  userId,
  review,
  bankAccountId,
  name,
  verificationDocuments,
  isBusinessAccount,
  IDINUserInfo,
  kvkInfo,
}) => {
  const [id] = useState<string>(nanoid);
  const {
    modalVisible,
    // reviewState,
    defaultReviewNote,
    // reviewNote,
    isReviewed,
    handleCancel,
    handleOk,
    modalTitle,
    note,
    setNote,
    value,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    bankAccountId,
    actionType: 'REVIEW_REPRESENTATIVE',
    defaultNote: DefaultNotes.ReviewRepresentative,
    review,
  });

  const { documentBlobs, documentUrls } = useVerificationDocuments({
    verificationDocuments,
    documentType: 'UBO',
  });

  if (!isBusinessAccount || isBusinessAccount === "false") {
    return null;
  }

  const options: OptionsPropType = [
    { name: 'default', value: '1', label: defaultReviewNote },
    { name: 'other', value: '2', label: 'Other (O)' },
  ];

  if (!review?.isRequired) {
    return null;
  }

  if (isReviewed) {
    return null; // An empty dashboard is more favorable for the reviewer
    // return (
    //   <ReviewedCard title="Review Business Representative" state={reviewState} note={reviewNote} />
    // );
  }
  return (
    <>
      <PanelCardContainer>
        <PanelCardHeader>
          <PanelCardTitle>Review Business Representative</PanelCardTitle>
        </PanelCardHeader>
        <PanelCardItem>
          <PanelCardItemName>Flow Name</PanelCardItemName>
          <PanelCardItemValue>{name}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>iDin Name</PanelCardItemName>
          <PanelCardItemValue>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>KVK Business Name</PanelCardItemName>
          <PanelCardItemValue isRed>{kvkInfo?.tradeNames?.businessName || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Description</PanelCardItemName>
          <PanelCardItemValue isRed>
            {kvkInfo?.businessActivities?.map(a => a.sbiCodeDescription).join(', ') || '-'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Website</PanelCardItemName>
          <PanelCardItemValue>
            {kvkInfo?.websites?.map((site) => (
              <PanelCardItemLink href={`http://${site}`} target="_blank">
                {site}
              </PanelCardItemLink>
            )) || '-'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>Document</PanelCardItemName>
          <PanelCardItemValue>
            {documentUrls.length > 0
              ? documentUrls.map((documentUrl, idx) => (
                  <AttachedFileViewer
                    key={id}
                    mimeType={documentBlobs[idx].type}
                    url={documentUrl}
                  />
                ))
              : 'No image uploaded'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Bank account type</PanelCardItemName>
          <PanelCardItemValue>
            {isBusinessAccount === 'true' ? 'Business' : 'Personal'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardControls>
          <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>
            Approve
          </PanelCardButton>
          <PanelCardButton onClick={() => onClickHandler('REJECT')}>Reject</PanelCardButton>
        </PanelCardControls>
      </PanelCardContainer>

      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        optionValue={value.toString()}
        options={options}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </>
  );
};

export default ReviewRepresentative;
