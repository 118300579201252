import React from 'react';
import {
  PanelCardButton,
  PanelCardContainer,
  PanelCardControls,
  PanelCardHeader,
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardTitle,
} from '../../SidePanel.elements';
import { PanelCardStandardProps } from '../types';
import { useReviewCardState } from './hooks';
import { DefaultNotes } from './defaultNotes';
import { IDINUserInfo } from '../../../../../domain/signicat';
import { ReviewModal } from '..';
import { OptionsPropType } from '@atlaskit/radio/types';

interface ReviewUserNameProps extends PanelCardStandardProps {
  IDINUserInfo: IDINUserInfo | null;
}

const ReviewUserName: React.FC<ReviewUserNameProps> = ({ userId, review, name, IDINUserInfo }) => {
  const {
    modalVisible,
    defaultReviewNote,
    reviewNote,
    isReviewed,
    handleCancel,
    handleOk,
    modalTitle,
    note,
    value,
    setNote,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    actionType: 'REVIEW_USER_NAME',
    defaultNote: DefaultNotes.ReviewUserName,
    review,
  });

  // No info to show, hide card instead
  if (!review?.isRequired && !isReviewed && !reviewNote) {
    return null;
  }

  if (isReviewed || !review?.isRequired) {
    return null; // An empty dashboard is more favorable for the reviewer
    // return <ReviewedCard title="Review iDin Name" state={reviewState} note={reviewNote} />;
  }

  const options: OptionsPropType = [
    { name: 'default', value: '1', label: defaultReviewNote },
    { name: 'other', value: '2', label: 'Other (O)' },
  ];

  return (
    <>
      <PanelCardContainer>
        <PanelCardHeader>
          <PanelCardTitle>Review iDin Name</PanelCardTitle>
        </PanelCardHeader>
        <PanelCardItem>
          <PanelCardItemName>Flow Name</PanelCardItemName>
          <PanelCardItemValue>{name}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>iDin Name</PanelCardItemName>
          <PanelCardItemValue isRed>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>iDin Given Name</PanelCardItemName>
          <PanelCardItemValue isRed>{IDINUserInfo?.given_name || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>iDin Family Name</PanelCardItemName>
          <PanelCardItemValue isRed>{IDINUserInfo?.family_name || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardControls>
          <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>
            Approve
          </PanelCardButton>
          <PanelCardButton onClick={() => onClickHandler('REJECT')}>Reject</PanelCardButton>
        </PanelCardControls>
      </PanelCardContainer>

      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        options={options}
        optionValue={value.toString()}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </>
  );
};

export default ReviewUserName;
