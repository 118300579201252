export enum actionTitles {
  REQUEST_ADD_BANK = 'Request Bank Add',
  REQUEST_ID_VERIFICATION = 'Request ID Verification',
  PROVIDE_BUSINESS_NAME = 'Provide Business Name',
  PROVIDE_KVK_NUMBER = 'Provide KVK Number',
  PROVIDE_BUSINESS_ACCOUNT_CONFIRMATION = 'Provide Business Account Confirmation',
  PROVIDE_KVK_COMPANY_DATA = 'Provide KVK Company Data',
  PROVIDE_BUSINESS_OWNER_CONFIRMATION = 'Provide Business Owner Confirmation',
  PROVIDE_KVK_UITREKSEL = 'Provide KVK Exract',
  PROVIDE_ONLY_UBO_CONFIRMATION = 'Provide Only UBO Confirmation',
  PROVIDE_UBO = 'Provide UBO',
  REMOVE_UBO = 'Remove UBO',
  PROVIDE_UBO_COMPLETE_CONFIRMATION = 'Provide UBO Complete Confirmation',
  PROVIDE_TRAPETS_UBO = 'Provide Trapets UBO',
  PROVIDE_USER_NAME = 'Provide User Name',
  PROVIDE_IDIN_USER_INFO = 'Provide iDIN User Info',
  PROVIDE_ID_DOCUMENT = 'Provide ID Document',
  PROVIDE_TRAPETS = 'Provide Trapets',
  REVIEW_BANK_ACCOUNT_HOLDER_NAMES = 'Review Bank Account Holder Name',
  REVIEW_REPRESENTATIVE = 'Review Represenative',
  REVIEW_UBO_VERIFICATION = 'Review UBO Verification',
  REVIEW_USER_NAME = 'Review iDIN Name',
  REVIEW_ID_VERIFICATION = 'Review ID Verification',
  REVIEW_PEP_HIT = 'Review Name is not on PEP-list',
  REVIEW_SANCTION_HIT = 'Review Name is not on Sanction-list',
  REVIEW_SUPPORT_OVERRIDE = 'Manually Reviewed',
}
