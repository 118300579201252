import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { getAllFlows } from '../../../../data/getAllFlows';
import { FlowAction, UserFlowItem } from '../../../../domain/userFlowItem';
import { TabContentWrapper } from '../SidePanel.elements';
import { styled } from 'styled-components';

const Card = styled.div`
  padding: 2em;
  background-color: #fff;
  border-radius: 0.5em;
`;

const FlowTab = ({ userId }: { userId: string }) => {
  const [loading, setLoading] = useState(true);
  const [flows, setFlows] = useState<UserFlowItem[]>([]);
  useEffect(() => {
    (async () => {
      const result = await getAllFlows(userId);
      setFlows(result);
      setLoading(false);
    })();
  }, [userId]);

  if (loading) {
    return (
      <TabContentWrapper>
        <Spinner />
      </TabContentWrapper>
    );
  }

  return (
    <TabContentWrapper>
      <UserFlows flows={flows} />
    </TabContentWrapper>
  );
};

export const daysAgo = (d: string) => {
  const date = new Date(d);
  const deltaDays = (date.getTime() - Date.now()) / (1000 * 3600 * 24);
  const formatter = new Intl.RelativeTimeFormat();
  return formatter.format(Math.round(deltaDays), 'days');
}

const UserFlows: React.FC<{ flows: UserFlowItem[] }> = ({ flows }) => {
  if (flows.length === 0) {
    return "This user has no Flows.";
  }

  return flows.map((flow) => (
    <Flow key={flow.id} flow={flow} />
  ))
};

const Flow: React.FC<{ flow: UserFlowItem }> = ({ flow }) => {
  const { settings } = flow;
  const { actions, triggers } = settings;

  const firstTrigger = triggers && triggers[0];

  const oneCent = flow.slug === 'one-cent-challenge';

  return (
    <Card style={{marginBottom: 24}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6
        }}
      >
        <h2>{flow.slug}</h2>
        <div>
          <small style={{ marginBottom: 10 }}>{flow.currentState}</small>
          <small style={{ marginLeft: 24, marginBottom: 10 }}>{flow.id}</small>
        </div>
        <div>
          <strong>Created:</strong> <span title={flow.createdAt ?? ''}>{daysAgo(flow.createdAt ?? '')}</span>
        </div>
        <div>
          <strong>Last update:</strong> <span title={flow.updatedAt ?? ''}>{daysAgo(flow.updatedAt ?? '')}</span>
        </div>
        {flow.nextExecution ? (
          <div>
            <strong>Next execution: </strong>
            <span title={flow.nextExecution}>
              {daysAgo(flow.nextExecution)}
            </span>
          </div>
        ) : firstTrigger?.rrule ? (
          <div>
            <strong color='red'>NO EXECUTION DATE SET</strong>
          </div>
        ) : null }
        <div>
          {firstTrigger && 'type' in firstTrigger ? TriggerTypes[firstTrigger.type] : oneCent ? 'One cent challenge' : 'Unknown flow type'}
          <span style={{marginLeft: 12}}>({flow.settings.mode})</span>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          flexGrow: 1,
          width: '100%',
        }}
      >
        <div
          style={{
            background: '#eee',
            borderRadius: 5,
            padding: 12,
            marginTop: 12,
            flexGrow: 1,
            flexBasis: '50%',
          }}
        >
          <h4 style={{ marginBottom: 12 }}>Triggers</h4>
          {!triggers && !oneCent && (<div>No Triggers, flow probably broken</div>)}
          { oneCent ? (
            <div>
              <strong>From:</strong> {settings.from ?? ''} <br/>
              <strong>To:</strong> {settings.to ?? ''} <br/>
            </div>
          ) : null
          }
          {triggers?.map((trigger, i) => (
            <div key={i}>
              <strong>Inbox:</strong> {trigger.rrule ? settings.from : trigger.accounts?.join(',')} <br/>
              {trigger?.filters?.length ? <h5>When:</h5> : null}
              {trigger?.filters?.map((filter) => (
                <div key={`${filter.iban}${filter.text}${filter.type}`}>
                  {TriggerFilters[trigger.direction][`${filter.type}`] ??
                    `Filter type not support by CRM ${filter.type}`}
                  <strong>
                    {' '}
                    {filter.amount ? filter.amount : null}
                    {filter.iban ? filter.iban : null}
                    {filter.text ? filter.text : null}
                  </strong>
                </div>
              ))}
              {trigger.rrule ? (
                <>
                  <h5>Every:</h5>
                  <div>
                    {trigger.rrule
                      .substring(6)
                      .split(';')
                      .map((r) => (
                        <div key={r}>{r}</div>
                      ))}
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </div>
        <div
          style={{
            background: '#eee',
            borderRadius: 5,
            padding: 12,
            marginTop: 12,
            flexGrow: 1,
            flexBasis: '50%',
          }}
        >
          <h4 style={{ marginBottom: 12 }}>Actions</h4>
          {actions?.map((a) => (
            <div key={a.slug} style={{ borderBottom: '1px solid #000', paddingTop: 8, paddingBottom: 8 }}>
              {actionToText(a)}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

const TriggerTypes = {
  transaction: 'Transaction-based',
  rrule: 'Date-based',
  buttonPress: 'Button-based',
  once: 'Triggers only once',
};

const TriggerFilters: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  incoming: {
    noop: 'Money comes in',
    senderNameContains: 'Sender name contains: ',
    senderNameIs: 'Sender name is: ',
    senderIBANContains: 'Sender IBAN contains: ',
    senderIBANIs: 'Sender IBAN is: ',
    descriptionContains: 'Description contains: ',
    descriptionIs: 'Description is: ',
    amountIs: 'Amount is: ',
    amountOver: 'Amount is over: ',
    amountUnder: 'Amount is under: ',
  },
  outgoing: {
    noop: 'Money going out',
  },
};

const actionToText = (action: FlowAction) => {
  /* percentage,
  fixed,
  fixed_up_to,
  forward,
  forward_over_x,
  from_whats_left,
  fill_to,
  // divide_equally,
  vat,
  notify, */
  const description = action.description ? `with description: "${action.description}"` : '';
  const from = action.from ? `from ${action.from}` : '';
  const to = `to ${action.to}`
  switch (action.type) {
    case 'percentage':
      return `Send ${action.amount}% ${from} ${to} ${description}`;
    case 'fixed':
      return `Send €${action.amount} ${from} ${to} ${description}`;
    case 'fixed_up_to':
      return `Send up to €${action.amount} ${from} ${to} ${description}`;
    case 'forward':
      return `Forward ${from} ${to} ${description}`;
    case 'forward_over_x':
      return `Forward all over €${action.amount} ${from} ${to} ${description}`;
    case 'from_whats_left':
      return `
        _______ From what's left _____________
      `;
    case 'fill_to':
      return `Fill ${from} ${to} €${action.amount}`;
    case 'vat':
      return `Send ${action.amount}% VAT ${from} ${to}`;
  }
  return `Action type unsupported: ${action.type}`;
};

export default FlowTab;
