import Button from '@atlaskit/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const CardSection = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  color: #6b778c;
  border-bottom: 3px solid #e0e0e0;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  font-size: 14px;
`;

const Label = styled.span`
  color: #6b778c;
  font-weight: 500;
`;

interface PartyDetailsProps {
  party: {
    accountHolderName?: string;
    countryOfAccount?: string;
    iban?: string;
    bic?: string;
    accountKind?: string;
    knownConnectedFlowUsers?: string[];
  };
}

export function PartyDetails({ party }: PartyDetailsProps) {
  const navigate = useNavigate();

  return (
    <CardSection>
      <SectionTitle>Party Details</SectionTitle>
      <InfoGrid>
        <Label>Name:</Label>
        <div>{party?.accountHolderName ?? 'Unknown'}</div>
        <Label>Connected users:</Label>
        <div>
          {party?.knownConnectedFlowUsers?.map((u) => (
            <Button key={u} onClick={() => navigate(u)}>
              View
            </Button>
          )) ?? 'Unknown'}
        </div>
        <Label>Country:</Label>
        <div>{party?.countryOfAccount ?? 'Unknown'}</div>
        <Label>IBAN:</Label>
        <div>{party?.iban ?? 'Unknown'}</div>
        <Label>BIC:</Label>
        <div>{party?.bic ?? 'Unknown'}</div>
        <Label>Account kind:</Label>
        <div>{party && 'accountKind' in party ? party.accountKind : 'Unknown'}</div>
      </InfoGrid>
    </CardSection>
  );
}
