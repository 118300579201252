import React, { useState } from 'react';
import { VerificationDocument } from '../../../../domain/verificationDocument';
import AttachedFileViewer from '../ReviewsTab/ReviewCards/AttachedFileViewer';
import useVerificationDocuments from '../ReviewsTab/ReviewCards/hooks/useVerificationDocuments';
import {
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
} from '../SidePanel.elements';
import { nanoid } from 'nanoid';

interface ReviewIDDocumentActionProps {
  name: string;
  verificationDocuments: VerificationDocument[];
  idName: boolean | string;
  idBirthDate: boolean | string;
}

const ReviewIDDocumentAction: React.FC<ReviewIDDocumentActionProps> = ({
  name,
  verificationDocuments,
  idName,
  idBirthDate,
}) => {
  const [id] = useState<string>(nanoid);
  const { documentBlobs, documentUrls } = useVerificationDocuments({
    verificationDocuments,
    documentType: 'ID',
  });
  return (
    <>
      <PanelCardItem>
        <PanelCardItemName>Flow Name</PanelCardItemName>
        <PanelCardItemValue>{name}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardSeparator />
      <PanelCardItem>
        <PanelCardItemName>Document</PanelCardItemName>
        <PanelCardItemValue>
          {documentUrls.length > 0
            ? documentUrls.map((documentUrl, idx) => (
                <AttachedFileViewer key={id} mimeType={documentBlobs[idx].type} url={documentUrl} />
              ))
            : 'No image uploaded'}
        </PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Name</PanelCardItemName>
        <PanelCardItemValue isRed>{typeof idName === 'boolean' ? '-' : idName}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Date of Birth</PanelCardItemName>
        <PanelCardItemValue isRed>
          {typeof idBirthDate === 'boolean' ? '-' : idBirthDate}
        </PanelCardItemValue>
      </PanelCardItem>
    </>
  );
};

export default ReviewIDDocumentAction;
