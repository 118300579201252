import styled from 'styled-components';

export const SideNavContainer = styled.aside`
  height: 100%;
  flex: 0 0 240px;
  background-color: white;
  box-shadow: inset -1px 0px 0px #f0f0f0;

  @media (max-width: 768px) {
    flex: 0 0 70px;

    *:not(.anticon):not(svg) {
      font-size: 0;
    }
    .ant-menu-item {
      display: flex;
      align-items: center;
    }
  }
`;
