import Select from '@atlaskit/select';
import React from 'react';
import { SelectOption } from './types';
import { Box, Flex } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { LoadingButton } from '@atlaskit/button';

interface IProps {
  search: () => void;
  setSelectValue: (value: string) => void;
  setSearchValue: (value: string) => void;
  selectOptions: SelectOption[];
  searchValue: string;
  focusRef?: React.RefObject<HTMLInputElement>;
  onFocus?: () => void;
  isLoading?: boolean;
}

const SearchBar = ({
  search,
  setSelectValue,
  setSearchValue,
  selectOptions = [],
  searchValue = '',
  focusRef,
  onFocus,
  isLoading = false,
}: IProps) => {
  return (
    <Box padding="space.200">
      <Flex justifyContent="end" gap="space.200">
        <Textfield
          onFocus={onFocus}
          ref={focusRef}
          elemBeforeInput={<SearchIcon size="small" label="icon" />}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          placeholder="Search..."
          isCompact
          label="Search"
          appearance="subtle"
          value={searchValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              search();
            }
          }}
        />
        <div style={{ width: '320px' }}>
          <Select
            spacing="compact"
            defaultValue={selectOptions[2]}
            onChange={(e) => e && setSelectValue(e?.value)}
            className="single-select"
            classNamePrefix="react-select"
            options={selectOptions}
            placeholder="Choose a city"
          />
        </div>
        <LoadingButton appearance="primary" onClick={() => search()} isLoading={isLoading}>
          Search
        </LoadingButton>
      </Flex>
    </Box>
  );
};

export { SearchBar };
