import { useState } from 'react';
import { VerificationDocument } from '../../../../../../domain/verificationDocument';
import { useDocumentBlobs } from '../../../../../../utils';

interface VerificationProps {
  verificationDocuments?: VerificationDocument[];
  documentType: string;
}

export default function useVerificationDocuments({
  verificationDocuments,
  documentType,
}: VerificationProps) {
  const [fileDetailsModalVisible, setFileDetailsModalVisible] = useState<boolean>(false);
  const documents = verificationDocuments?.filter(
    (d) => d.documentType === documentType
  ) as VerificationDocument[];

  const { documentBlobs } = useDocumentBlobs(documents);

  const documentUrls = documentBlobs.map((u) => URL.createObjectURL(u));

  return { documentBlobs, documentUrls, fileDetailsModalVisible, setFileDetailsModalVisible };
}
