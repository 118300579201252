import CryptoJS from 'crypto-js';

const JsonFormatter = {
  stringify: function (cipherParams: CryptoJS.lib.CipherParams) {
    // create json object with ciphertext
    const jsonObj: {ct: string, v: number, iv?: string, s?: string} = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), v: 2 };

    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString(CryptoJS.enc.Base64);
    }

    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString(CryptoJS.enc.Base64);
    }

    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr: string) {
    // parse json string
    const jsonObj = JSON.parse(jsonStr);

    // extract ciphertext from json object, and create cipher params object
    const cipherParams : CryptoJS.lib.CipherParams & {v?: number} = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });

    cipherParams.v = jsonObj.v;

    // optionally extract iv or salt

    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Base64.parse(jsonObj.iv);
    }

    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Base64.parse(jsonObj.s);
    }

    return cipherParams;
  },
};

export const encryptBase64String = (base64str: string, encryptionKey: string) => {
  return JsonFormatter.stringify(
    // { format: JsonFormatter } doesn't work, contrary to the docs
    CryptoJS.AES.encrypt(CryptoJS.enc.Base64.parse(base64str), encryptionKey)
  );
};

export const decryptToBase64String = (encrypted: string, encryptionKey: string) => {
  const json = JsonFormatter.parse(encrypted);
  const words = CryptoJS.AES.decrypt(json, encryptionKey);
  return json.v === 2 ? words.toString(CryptoJS.enc.Base64) : words.toString(CryptoJS.enc.Utf8);
};
