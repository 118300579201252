import React from "react"
import { TabContentWrapper } from "../SidePanel.elements"
import { useMonetaryAccounts } from "../../../../data/getAllMonetaryAccounts"
import Spinner from '@atlaskit/spinner';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import Card from "../../general/Card";
import { daysAgo } from "../FlowsTab/FlowsTab";
import moment from "moment";
import TransactionsPanel, { useAccountSelection } from "./TransactionsPanel";
import { useCrmUser } from "../../../../data/compliance";

const AccountsTab: React.FC<{ userId: string; }> = ({ userId }) => {
  const { isCompliance } = useCrmUser();
  const { selectedAccounts, setSelectedAccounts } = useAccountSelection();
  const { data, loading: accountsLoading } = useMonetaryAccounts(userId);
  const accounts = data?.getUserMonetaryAccounts;

  // Function to toggle account selection
  function toggleAccount(id: string) {
    setSelectedAccounts(prev => {
      const newSet = new Set(prev);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  }

  if (accountsLoading) {
    return (
      <TabContentWrapper>
        <Spinner />
      </TabContentWrapper>
    )
  }

  if (!accounts || accounts.length === 0) {
    return (
      <TabContentWrapper>
        No connected accounts
      </TabContentWrapper>
    )
  }

  return (
    <TabContentWrapper>
      {accounts.map(a => {
        return (
          <label htmlFor={`${a.id}-toggle`} style={{display: 'block', marginBottom: 24}} key={a.id}>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>{a.displayName} <small>({a.accountState})</small></h3>
                {isCompliance && (
                  <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'flex-end',  }}>
                    <Tooltip content="Toggle display of account transactions" hideTooltipOnClick>
                      <Toggle
                        id={`${a.id}-toggle`}
                        label="Show transactions"
                        isChecked={selectedAccounts.has(a.id)}
                        onChange={() => toggleAccount(a.id)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div><strong>{a.iban || a.id}</strong> <small>({a.kind})</small></div>
              <div>
                <strong>Created:</strong> <span title={a.createdAt ?? ''}>{daysAgo(a.createdAt ?? '')}</span>
              </div>
              <div>
                <strong>Last update:</strong> {moment(a.updatedAt).format('HH:mm:ss (YYYY-MM-DD)')}
              </div>
              <div>
                <strong>Legal name:</strong> {a.legalName}
              </div>
            </Card>
          </label>
        )
      })}
      <TransactionsPanel userId={userId} selectedAccounts={selectedAccounts} />
    </TabContentWrapper>
  )
}

export default AccountsTab
