import React from 'react';
import {
  PanelCardButton, PanelCardControls,
} from '../SidePanel.elements';
import { ReviewModal } from '../ReviewsTab';
import { useReviewCardState } from '../ReviewsTab/ReviewCards/hooks';
interface BanksTabProps {
  userId: string;
  bankAccountId: string;
}

const options = [
  { name: 'name', value: '1', label: 'Business details were sent by e-mail to customer-support and verified by compliance' },
  { name: 'other', value: '2', label: 'Other (O)' },
];

const ManualApprovalButton: React.FC<BanksTabProps> = ({ userId, bankAccountId }) => {
  const {
    modalVisible,
    handleCancel,
    handleOk,
    modalTitle,
    note,
    setNote,
    value,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    bankAccountId,
    actionType: 'REVIEW_SUPPORT_OVERRIDE',
    defaultNote: { APPROVE: options[0].label, REJECT: '' },
  });

  return (
    <PanelCardControls>
      <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>Manual approval override</PanelCardButton>
      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        options={options}
        optionValue={value.toString()}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </PanelCardControls>
  );
}

export default ManualApprovalButton;
