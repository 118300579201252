import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { detectMobile } from '../../../../../utils';
import logo from '../../../../../assets/icons/zoom.svg';

const AttachedFileViewerLogo = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
`;

const AttachedFileViewerContaiter = styled.div`
  filter: drop-shadow(0px 8px 16px rgba(black, 0.1));
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover {
    ${AttachedFileViewerLogo} {
      opacity: 1;
      visibility: visible;
      transition: all 0.25s ease-in-out;
    }
  }
`;

const EmbedPdfElement = styled.iframe`
  width: 100%;
  height: 375px;
  overflow: hidden;
  cursor: pointer;
`;

interface AttachedFileViewerProps {
  mimeType: string;
  url: string;
}

const AttachedFileViewer: React.FC<AttachedFileViewerProps> = ({ mimeType, url }) => {
  return (
    <AttachedFileViewerContaiter onClick={() => window.open(`${url}`, '_blank')}>
      {!detectMobile() && (
        <AttachedFileViewerLogo>
          <img src={logo} width="14px" alt="O" />
        </AttachedFileViewerLogo>
      )}
      {mimeType === 'application/pdf' ? (
        detectMobile() ? (
          <Button
            appearance="link"
            onClick={() => window.open(`${url}`, '_blank')}
            style={{ padding: '4px 0' }}
          >
            Download
          </Button>
        ) : (
          <EmbedPdfElement scrolling="no" src={url} />
        )
      ) : (
        <a href={url} target="_blank">
          <img src={url} style={{ width: '100%', cursor: 'pointer' }} />
        </a>
      )}
    </AttachedFileViewerContaiter>
  );
};

export default AttachedFileViewer;
