export interface DefaultNote {
  APPROVE: string;
  REJECT: string;
}

interface DefaultNotes {
  ReviewIDDocument: DefaultNote;
  ReviewRepresentative: DefaultNote;
  ReviewUserName: DefaultNote;
  ReviewAccountHolderName: DefaultNote;
  ReviewPEPHit: DefaultNote;
  ReviewSanctionHit: DefaultNote;
}

export const DefaultNotes: DefaultNotes = {
  ReviewIDDocument: {
    APPROVE: 'ID Document approved',
    REJECT: 'ID Document rejected',
  },
  ReviewRepresentative: {
    APPROVE: 'Natural person behind business is qualified',
    REJECT: 'Natural person behind business is not qualified',
  },
  ReviewUserName: {
    APPROVE: 'Name is correct',
    REJECT: 'Name is incorrect',
  },
  ReviewAccountHolderName: {
    APPROVE: 'Bank account holders name is correct',
    REJECT: 'Bank account holders name is incorrect',
  },
  ReviewPEPHit: {
    APPROVE: 'False-positive PEP name match',
    REJECT: 'User is a PEP',
  },
  ReviewSanctionHit: {
    APPROVE: 'False-positive sanction-list name match',
    REJECT: 'User is sanctioned',
  },
};
