import React, { useState } from 'react';
import {
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardItemLink,
} from '../SidePanel.elements';
import { nanoid } from 'nanoid';
import { VerificationDocument } from '../../../../domain/verificationDocument';
import { useVerificationDocuments } from '../ReviewsTab/ReviewCards/hooks';
import { AttachedFileViewer } from '../ReviewsTab/ReviewCards';
import { IDINUserInfo } from '../../../../domain/signicat';
import { useKVKData } from '../SidePanel';

interface ReviewRepresentativeActionsProps {
  userId: string,
  bankAccountId?: string,
  name: string;
  isBusinessAccount?: boolean | 'true' | 'false';
  verificationDocuments: VerificationDocument[];
  IDINUserInfo: IDINUserInfo | null;
}
const ReviewRepresentativeActions: React.FC<ReviewRepresentativeActionsProps> = (props) => {
  const {
    name,
    isBusinessAccount,
    verificationDocuments,
    IDINUserInfo,
  } = props;
  const kvkInfo = useKVKData(props);

  const [id] = useState<string>(nanoid);
  const { documentBlobs, documentUrls } = useVerificationDocuments({
    verificationDocuments,
    documentType: 'UBO',
  });
  return (
    <>
      <PanelCardItem>
        <PanelCardItemName>Flow Name</PanelCardItemName>
        <PanelCardItemValue>{name}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>iDin Name</PanelCardItemName>
        <PanelCardItemValue>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardSeparator />
      <PanelCardItem>
        <PanelCardItemName>KVK Business Name</PanelCardItemName>
        <PanelCardItemValue isRed>{kvkInfo?.tradeNames?.businessName || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Description</PanelCardItemName>
        <PanelCardItemValue isRed>
          {kvkInfo?.businessActivities?.map(a => a.sbiCodeDescription).join(', ') || '-'}
        </PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Website</PanelCardItemName>
        <PanelCardItemValue>
          {kvkInfo?.websites?.map((site) => (
            <PanelCardItemLink href={`http://${site}`} target="_blank">
              {site}
            </PanelCardItemLink>
          )) || '-'}
        </PanelCardItemValue>
      </PanelCardItem>
      <PanelCardSeparator />
      <PanelCardItem>
        <PanelCardItemName>Document</PanelCardItemName>
        <PanelCardItemValue>
          {documentUrls.length > 0
            ? documentUrls.map((documentUrl, idx) => (
                <AttachedFileViewer key={id} mimeType={documentBlobs[idx].type} url={documentUrl} />
              ))
            : 'No image uploaded'}
        </PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Bank account type</PanelCardItemName>
        <PanelCardItemValue>
          {isBusinessAccount === 'true' ? 'Business' : 'Personal'}
        </PanelCardItemValue>
      </PanelCardItem>
    </>
  );
};

export default ReviewRepresentativeActions;
