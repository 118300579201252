import React from 'react';
import { IDINUserInfo } from '../../../../domain/signicat';
import {
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
} from '../../SidePanel/SidePanel.elements';

interface ReviewUserNameActionProps {
  name: string;
  IDINUserInfo: IDINUserInfo | null;
}

const ReviewUserNameAction: React.FC<ReviewUserNameActionProps> = ({ name, IDINUserInfo }) => {
  return (
    <>
      <PanelCardItem>
        <PanelCardItemName>Flow Name</PanelCardItemName>
        <PanelCardItemValue>{name}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardSeparator />
      <PanelCardItem>
        <PanelCardItemName>iDin Name</PanelCardItemName>
        <PanelCardItemValue isRed>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>iDin Given Name</PanelCardItemName>
        <PanelCardItemValue isRed>{IDINUserInfo?.given_name || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>iDin Family Name</PanelCardItemName>
        <PanelCardItemValue isRed>{IDINUserInfo?.family_name || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardSeparator />
    </>
  );
};

export default ReviewUserNameAction;
