import gql from 'graphql-tag';

export const listPendingReviewsQuery = gql`
  query {
    listPendingReviews {
      userId
      userName {
        given_name
        family_name
      }
      lastUpdatedDate
      hasPendingReview
      isRejected
      riskQualification
    }
  }
`;

export const listRejectedUsersQuery = gql`
  query {
    listRejectedUsers {
      userId
      userName {
        given_name
        family_name
      }
      lastUpdatedDate
      hasPendingReview
      isRejected
      riskQualification
    }
  }
`;

export const searchUsersQuery = gql`
  query($field: String!, $searchTerm: String!) {
    searchUsers(field: $field, searchTerm: $searchTerm) {
      id
      given_name
      email
    }
  }
`;

export const getUserQuery = gql`
  query($userId: String!) {
    getUser(userId: $userId) {
      user {
        id
        given_name
        family_name
        email
        email_verified
        createDate
        lastModifiedDate
        enabled
        status
        subscription
      }
      kycState {
        naturalPerson {
          verificationStatus
          rejectReason
          answers {
            userName {
              isRequired
              hasValue
              value
            }
            idinUser {
              isRequired
              hasValue
              value
            }
            trapetsResult {
              isRequired
              hasValue
              value
            }
            idName {
              isRequired
              hasValue
              value
            }
            idBirthDate {
              isRequired
              hasValue
              value
            }
            idDocumentFile {
              isRequired
              hasValue
              value
            }
          }
          reviews {
            userName {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            idDocument {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
          }
        }
        bankAccounts {
          bankAccountId
          bankAccountHolderNames
          verificationStatus
          rejectReason
          answers {
            isBusinessAccount {
              isRequired
              hasValue
              value
            }
            isBusinessAccountConfirmation {
              isRequired
              hasValue
              value
            }
            businessName {
              isRequired
              hasValue
              value
            }
            kvkNumber {
              isRequired
              hasValue
              value
            }
            isBusinessOwner {
              isRequired
              hasValue
              value
            }
            isOnlyUBO {
              isRequired
              hasValue
              value
            }
            hasEnteredAllUBOs {
              isRequired
              hasValue
              value
            }
            kvkUitrekselDocumentFile {
              isRequired
              hasValue
              value
            }
            otherUBOs {
              isRequired
              hasValue
              value
            }
            kvkCompany {
              isRequired
              hasValue
              value
            }
            uboTrapetsResults {
              isRequired
              hasValue
              value
            }
          }
          reviews {
            bankAccountHolderNames {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            isRepresentative {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            ubo {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
          }
        }
        actions
        status {
          userId
          userName {
            given_name
            family_name
          }
          lastUpdatedDate
          hasPendingReview
          isRejected
          riskQualification
        }
      }
    }
  }
`;

export const getVerificationDocumentsQuery = gql`
  query($userId: String!) {
    getVerificationDocuments(userId: $userId) {
      userId
      documentId
      documentType
      bankAccountId
      url
      key
      kv
      createdAt
      uploadedAt
    }
  }
`;

export const getUserLogsQuery = gql`
  query($userId: String!, $fromDate: String, $toDate: String) {
    getUserLogs(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
      uid
      timestamp
      svc
      op
      info
      jsonData
    }
  }
`;

export const getAllFlowsQuery = gql`
  query($userId: String!) {
    getAllFlows(userId: $userId) {
      userId
      createdAt
      updatedAt
      versions {
        id
        slug
        templateVersion
        frozen {
          reason
          since
          details
        }
        currentState
        providerId
        settings {
          from
          to
          schedule
          transferAmount
          transferAmountType
          triggers {
            type
            direction
            accounts
            filters {
              type
              amount
              iban
              text
            }
            buttonName
            rrule
          }
          actions {
            slug
            to
            type
            amount
            description
          }
          mode
        }
        nextExecution
      }
      id
      slug
      currentState
      providerId
      settings {
        from
        to
        schedule
        transferAmount
        transferAmountType
        triggers {
          type
          direction
          accounts
          filters {
            type
            amount
            iban
            text
          }
          buttonName
          rrule
        }
        actions {
          slug
          to
          type
          amount
          description
          from
        }
        mode
      }
      nextExecution
      templateVersion
      frozen {
        reason
        since
        details
      }
    }
  }
`;
// export const getIdinUserQuery = gql`
//   query($userId: String!) {
//     getVerificationDocuments(userId: $userId) {
//       userId
//       documentId
//       documentType
//       bankAccountId
//       url
//       key
//       kv
//       createdAt
//       uploadedAt
//     }
//   }
// `;
export const getUserWithDocuments = gql`
  query getUserWithDocuments($userId: String!) {
    getUser(userId: $userId) {
      user {
        id
        given_name
        family_name
        email
        email_verified
        createDate
        lastModifiedDate
        enabled
        status
        subscription
      }
      kycState {
        naturalPerson {
          verificationStatus
          rejectReason
          answers {
            userName {
              isRequired
              hasValue
              value
            }
            idinUser {
              isRequired
              hasValue
              value
            }
            trapetsResult {
              isRequired
              hasValue
              value
            }
            idName {
              isRequired
              hasValue
              value
            }
            idBirthDate {
              isRequired
              hasValue
              value
            }
            idDocumentFile {
              isRequired
              hasValue
              value
            }
          }
          reviews {
            userName {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            idDocument {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
          }
        }
        bankAccounts {
          bankAccountId
          bankAccountHolderNames
          verificationStatus
          rejectReason
          answers {
            isBusinessAccount {
              isRequired
              hasValue
              value
            }
            isBusinessAccountConfirmation {
              isRequired
              hasValue
              value
            }
            businessName {
              isRequired
              hasValue
              value
            }
            kvkNumber {
              isRequired
              hasValue
              value
            }
            isBusinessOwner {
              isRequired
              hasValue
              value
            }
            isOnlyUBO {
              isRequired
              hasValue
              value
            }
            hasEnteredAllUBOs {
              isRequired
              hasValue
              value
            }
            kvkUitrekselDocumentFile {
              isRequired
              hasValue
              value
            }
            otherUBOs {
              isRequired
              hasValue
              value
            }
            kvkCompany {
              isRequired
              hasValue
              value
            }
            uboTrapetsResults {
              isRequired
              hasValue
              value
            }
          }
          reviews {
            bankAccountHolderNames {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            isRepresentative {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
            ubo {
              isRequired
              hasValue
              value {
                reviewByCRMUser {
                  id
                  email
                }
                reviewResult
                note
              }
            }
          }
        }
        actions
        status {
          userId
          userName {
            given_name
            family_name
          }
          lastUpdatedDate
          hasPendingReview
          isRejected
          riskQualification
        }
      }
    }
    getVerificationDocuments(userId: $userId) {
      userId
      documentId
      documentType
      bankAccountId
      url
      key
      kv
      createdAt
      uploadedAt
    }
    getIDINProvidedData(userId: $userId) {
      given_name
      family_name
      birthdate
    }
  }
`;

export const getKVKProvidedData = gql`
  query getKVKProvidedData($userId: String!, $bankAccountId: String) {
    getKVKProvidedData(userId: $userId, bankAccountId: $bankAccountId) {
      tradeNames {
        businessName
      }
      businessActivities {
        sbiCode
        sbiCodeDescription
        isMainSbi
      }
      websites
    }
  }
`;

export const getAllMonetaryAccountsQuery = gql`
  query getUserMonetaryAccounts($userId: String!) {
    getUserMonetaryAccounts(userId: $userId) {
      id
      bankAccountId
      createdAt
      updatedAt
      iban
      displayName
      legalName
      kind
      accountState
      onlyWithSCA
    }
  }
`;

export const transactionFields = `
  mtid
  kind
  description
  remittanceInformationUnstructured
  ingested
  expiresAt
  mutation {
    cents
    currency
  }
  balanceAfterMutation {
    cents
    currency
  }
  forex {
    cents
    currency
  }
  occurred
  settled
  party {
    bic
    bban
    iban
    nonSepa
    accountHolderName
    countryOfAccount
    accountKind
    knownConnectedFlowUsers
  }
  counterParty {
    bic
    bban
    iban
    nonSepa
    accountHolderName
    countryOfAccount
    accountKind
    knownConnectedFlowUsers
  }
  status
  bankRef
`;

export const getHighRiskTransactionsByMonthQuery = gql`
  query getHighRiskTransactionsByMonth($year: Int!, $month: Int!) {
    getHighRiskTransactionsByMonth(year: $year, month: $month) {
      year
      month
      week
      alertType
      totalAmount {
        cents
        currency
      }
      transactions {
        id
        ${transactionFields}
      }
    }
  }
`;

export const getHighRiskTransactionReviewsQuery = gql`
  query getHighRiskTransactionReviews($year: Int!, $month: Int!) {
    getHighRiskTransactionReviews(year: $year, month: $month) {
      id
      status
      note
    }
  }
`;

export const getMonthOfTransactionsQuery = gql`
  query getMonthOfTransactions(
    $userId: String!
    $month: String!
    $maid: String!
  ) {
    getMonthOfTransactions(userId: $userId, month: $month, maid: $maid) {
      ${transactionFields}
    }
  }
`;
