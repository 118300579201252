import React from 'react';
import {
  PanelCardContainer,
  PanelCardHeader,
  PanelCardTitle,
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardDate,
  TabContentWrapper,
} from '../SidePanel.elements';
import ReactJson from 'react-json-view';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import moment from 'moment';
import { ReviewResult } from '../../../../domain/actions';
import { actionTitles } from './actionTitles';
import ReviewUserNameAction from './ReviewUserNameAction';
import ReviewAccountHoldersNameAction from './ReviewAccountHoldersNameAction';
import ReviewIDDocumentAction from './ReviewIDDocumentAction';
import { VerificationDocument } from '../../../../domain/verificationDocument';
import ReviewRepresentativeActions from './ReviewRepresentativeActions';
import { IDINUserInfo } from '../../../../domain/signicat';
import { NaturalPersonAnswers } from '../../../../domain/naturalPerson';
import { BankAccountState } from '../../../../domain/bankAccount';

interface CardAction {
  type: keyof typeof actionTitles;
  bankAccountId?: string;
  note?: string;
  reviewByCRMUser?: {
    email: string;
    id: string;
  };
  executionDate?: string;
  reviewResult?: ReviewResult;
  isBusinessAccount?: boolean;
}

interface HistoryTabProps {
  userId: string;
  actions: CardAction[];
  name: string;
  verificationDocuments: VerificationDocument[];
  IDINInfo: IDINUserInfo | null;
  answers: NaturalPersonAnswers;
  bankAccounts: BankAccountState[];
}
const HistoryTab: React.FC<HistoryTabProps> = ({
  userId,
  actions,
  name,
  verificationDocuments,
  IDINInfo,
  answers,
  bankAccounts,
}) => {
  return (
    <TabContentWrapper>
      {actions
        .slice()
        .sort((a, b) =>
          b.executionDate !== undefined && a.executionDate !== undefined
            ? +new Date(b.executionDate) - +new Date(a.executionDate)
            : 0
        )
        .map((action, idx) => (
          <PanelCardContainer key={idx}>
            <PanelCardHeader>
              <PanelCardTitle>{actionTitles[action.type]}</PanelCardTitle>
              {action.reviewResult === 'APPROVE' && (
                <CheckCircleOutlineIcon primaryColor="#1890FF" label="check" size="medium" />
              )}
              {action.reviewResult === 'REJECT' && (
                <CrossCircleIcon primaryColor="#FF4D4F" label="cross" size="small" />
              )}
            </PanelCardHeader>
            {action.type === 'REVIEW_USER_NAME' && (
              <ReviewUserNameAction name={name} IDINUserInfo={IDINInfo} />
            )}
            {(action.type === 'REVIEW_BANK_ACCOUNT_HOLDER_NAMES' || action.type === 'REVIEW_SUPPORT_OVERRIDE') &&
              bankAccounts.filter(a => a.bankAccountId == action.bankAccountId).map(({ bankAccountHolderNames, reviews }) => {
                return reviews.bankAccountHolderNames.hasValue &&
                  reviews.bankAccountHolderNames.value ? (
                  <ReviewAccountHoldersNameAction
                    userId={userId}
                    bankAccountId={action.bankAccountId}
                    name={name}
                    isBusinessAccount={action.isBusinessAccount}
                    IDINUserInfo={IDINInfo}
                    bankAccountHolderNames={bankAccountHolderNames}
                  />
                ) : null;
              })}
            {action.type === 'REVIEW_ID_VERIFICATION' && (
              <ReviewIDDocumentAction
                idName={answers.idName.hasValue && answers.idName.value}
                idBirthDate={answers.idBirthDate.hasValue && answers.idBirthDate.value}
                name={name}
                verificationDocuments={verificationDocuments}
              />
            )}
            {action.type === 'REVIEW_REPRESENTATIVE' && (
              <ReviewRepresentativeActions
                userId={userId}
                bankAccountId={action.bankAccountId}
                name={name}
                verificationDocuments={verificationDocuments}
                isBusinessAccount={action.isBusinessAccount}
                IDINUserInfo={IDINInfo}
              />
            )}
            {action.note && (
              <PanelCardItem>
                <PanelCardItemName>Note</PanelCardItemName>
                <PanelCardItemValue>{action.note}</PanelCardItemValue>
              </PanelCardItem>
            )}
            {action.reviewByCRMUser && (
              <PanelCardItem>
                <PanelCardItemName>Reviewed by</PanelCardItemName>
                <PanelCardItemValue>{action.reviewByCRMUser.email}</PanelCardItemValue>
              </PanelCardItem>
            )}
            {action.executionDate && (
              <PanelCardDate>
                {moment(action.executionDate).format('YYYY-MM-DD hh:mm')}
              </PanelCardDate>
            )}
            <ReactJson
              src={action}
              name="action object"
              theme="shapeshifter:inverted"
              displayDataTypes={false}
              collapsed={true}
              indentWidth={3}
              style={{ marginTop: '20px' }}
            />
          </PanelCardContainer>
        ))}
    </TabContentWrapper>
  );
};

export default HistoryTab;
