import React, { useState } from 'react';
import {
  PanelCardButton,
  PanelCardContainer,
  PanelCardControls,
  PanelCardHeader,
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardTitle,
} from '../../SidePanel.elements';
import { AttachedFileViewer } from '.';
import { PanelCardStandardProps } from '../types';
import { useReviewCardState, useVerificationDocuments } from './hooks';
import { DefaultNotes } from './defaultNotes';
import { VerificationDocument } from '../../../../../domain/verificationDocument';
import { nanoid } from 'nanoid';
import { OptionsPropType } from '@atlaskit/radio/types';
import { ReviewModal } from '..';

interface ReviewIDDocumentProps extends PanelCardStandardProps {
  verificationDocuments: VerificationDocument[];
  idName: boolean | string;
  idBirthDate: boolean | string;
}

const ReviewIDDocument: React.FC<ReviewIDDocumentProps> = ({
  userId,
  review,
  verificationDocuments,
  name,
  idName,
  idBirthDate,
}) => {
  const [id] = useState<string>(nanoid);
  const {
    modalVisible,
    handleCancel,
    handleOk,
    modalTitle,
    isReviewed,
    note,
    setNote,
    defaultReviewNote,
    reviewNote,
    value,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    actionType: 'REVIEW_ID_VERIFICATION',
    defaultNote: DefaultNotes.ReviewIDDocument,
    review,
  });

  const { documentBlobs, documentUrls } = useVerificationDocuments({
    verificationDocuments,
    documentType: 'ID',
  });

  // No info to show, hide card instead
  if (!review?.isRequired && !isReviewed && !reviewNote) {
    return null;
  }

  if (isReviewed || !review?.isRequired) {
    return null; // An empty dashboard is more favorable for the reviewers.
    // return <ReviewedCard title="Review ID Document" state={reviewState} note={reviewNote} />;
  }

  const options: OptionsPropType = [
    { name: 'default', value: '1', label: defaultReviewNote },
    { name: 'other', value: '2', label: 'Other (O)' },
  ];

  return (
    <>
      <PanelCardContainer>
        <PanelCardHeader>
          <PanelCardTitle>Review ID Document</PanelCardTitle>
        </PanelCardHeader>
        <PanelCardItem>
          <PanelCardItemName>Flow Name</PanelCardItemName>
          <PanelCardItemValue>{name}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>Document</PanelCardItemName>
          <PanelCardItemValue>
            {documentUrls.length > 0
              ? documentUrls.map((documentUrl, idx) => (
                  <AttachedFileViewer
                    key={id}
                    mimeType={documentBlobs[idx].type}
                    url={documentUrl}
                  />
                ))
              : 'No image uploaded'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Name</PanelCardItemName>
          <PanelCardItemValue isRed>
            {typeof idName === 'boolean' ? '-' : idName}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Date of Birth</PanelCardItemName>
          <PanelCardItemValue isRed>
            {typeof idBirthDate === 'boolean' ? '-' : idBirthDate}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardControls>
          <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>
            Approve
          </PanelCardButton>
          <PanelCardButton onClick={() => onClickHandler('REJECT')}>Reject</PanelCardButton>
        </PanelCardControls>
      </PanelCardContainer>

      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        options={options}
        optionValue={value.toString()}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </>
  );
};

export default ReviewIDDocument;
