import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Spinner from '@atlaskit/spinner';
import { getMonthOfTransactionsQuery } from '../../../../data/query';
import { client } from '../../../../data/client';
import { TabContentWrapper } from '../SidePanel.elements';
import styled from 'styled-components';
import { formatAmount, MonetaryTransactionInfo } from '../../../../data/getHighRiskTransactions';
import MonthPicker from '../../general/MonthPicker';

export const TransactionsContentWrapper = styled(TabContentWrapper)`
  height: calc(100vh);
  padding: 0;
`;

interface TransactionsPanelContainerProps {
  isVisible?: boolean;
}

export const TransactionsPanelContainer = styled.aside<TransactionsPanelContainerProps>`
  font-family: 'Basier Circle';
  height: 100vh;
  position: fixed;
  z-index: 0;
  right: 0;
  background-color: #f5f5f5;
  box-shadow: 0 0 600px rgba(0, 0, 0, 0.5);
  transition: transform 0.1s ease-in-out;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
  @media screen and (min-width: 1200px) {
    top: 0;
    width: calc(100vw - 550px);
    right: ${({ isVisible }) => (isVisible ? '500px' : '-1350px')};
  }
`;

const TransactionsTable = styled.table`
  border-collapse: collapse;
  margin-top: 64px;

  thead {
    background-color: white;
    border-bottom: 1px solid #dfe1e6;
    position: sticky;
    top: 64px;
    z-index: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  }

  th,
  td {
    padding: 8px 2px;
    text-align: left;
    border-bottom: 1px solid #dfe1e6;
  }

  th:first-child,
  td:first-child,
  th:last-child,
  td:last-child {
    padding: 8px 16px;
  }

  th {
    font-weight: 600;
    color: #5e6c84;
    text-transform: uppercase;
    font-size: 12px;
  }

  tr:hover {
    background-color: #f1f4f7;
  }
  .date-column {
    min-width: 120px;
  }
  .party-column {
    vertical-align: top;
    min-width: 240px;
  }
  .kind-column {
    font-size: 80%;
    font-weight: 600;
  }
  .amount-column {
    min-width: 100px;
    padding-right: 8px;
    text-align: right;
  }
`;

const FixedHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
`;

export const useAccountSelection = () => {
  const [selectedAccounts, setSelectedAccounts] = useState<Set<string>>(new Set());
  return { selectedAccounts, setSelectedAccounts };
};

export const useTransactions = (userId: string, selectedAccounts: Set<string>) => {
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [startYear, setStartYear] = useState(moment().year());
  const [startMonth, setStartMonth] = useState(moment().month());
  const [endYear, setEndYear] = useState(moment().year());
  const [endMonth, setEndMonth] = useState(moment().month());
  const [transactions, setTransactions] = useState<any[]>([]);

  // Fetch transactions when selected accounts or date range changes
  useEffect(() => {
    const fetchTransactions = async () => {
      if (selectedAccounts.size === 0) {
        setTransactions([]);
        return;
      }

      setTransactionsLoading(true);

      const accountQueries = Array.from(selectedAccounts).map(async (accountId) => {
        const startMoment = moment().year(startYear).month(startMonth).startOf('month');
        const endMoment = moment().year(endYear).month(endMonth).endOf('month');

        // Generate all month queries in parallel
        const monthQueries = [];
        while (startMoment.isSameOrBefore(endMoment, 'month')) {
          const monthStr = startMoment.format('YYYY-MM');

          const queryPromise = client
            .query({
              query: getMonthOfTransactionsQuery,
              variables: {
                userId,
                month: monthStr,
                maid: accountId,
              },
            })
            .then(({ data }) => {
              if (data?.getMonthOfTransactions) {
                return data.getMonthOfTransactions;
              }
              return [];
            })
            .catch((error) => {
              console.error(
                `Error fetching transactions for account ${accountId} in ${monthStr}:`,
                error
              );
              return [];
            });

          monthQueries.push(queryPromise);
          startMoment.add(1, 'month');
        }

        // Wait for all month queries for this account to complete
        const accountTransactions = await Promise.all(monthQueries);
        return accountTransactions.flat();
      });

      // Wait for all account queries to complete
      const accountsTransactions = await Promise.all(accountQueries);

      // Flatten and sort transactions
      const flattenedTransactions = accountsTransactions.flat();
      setTransactions(
        flattenedTransactions.sort((a, b) => moment(b.occurred).diff(moment(a.occurred)))
      );
      setTransactionsLoading(false);
    };

    fetchTransactions();
  }, [selectedAccounts, startYear, startMonth, endYear, endMonth, userId]);

  return {
    selectedAccounts,
    startYear,
    startMonth,
    endYear,
    endMonth,
    transactionsLoading,
    transactions,
    setStartYear,
    setStartMonth,
    setEndYear,
    setEndMonth,
  };
};

const getCountryFlag = (countryCode: string): string => {
  // Map of country codes to flag emojis
  const countryFlags: { [key: string]: string } = {
    AT: '🇦🇹', // Austria
    BE: '🇧🇪', // Belgium
    BG: '🇧🇬', // Bulgaria
    HR: '🇭🇷', // Croatia
    CY: '🇨🇾', // Cyprus
    CZ: '🇨🇿', // Czech Republic
    DK: '🇩🇰', // Denmark
    EE: '🇪🇪', // Estonia
    FI: '🇫🇮', // Finland
    FR: '🇫🇷', // France
    DE: '🇩🇪', // Germany
    GR: '🇬🇷', // Greece
    HU: '🇭🇺', // Hungary
    IE: '🇮🇪', // Ireland
    IT: '🇮🇹', // Italy
    LV: '🇱🇻', // Latvia
    LT: '🇱🇹', // Lithuania
    LU: '🇱🇺', // Luxembourg
    MT: '🇲🇹', // Malta
    NL: '🇳🇱', // Netherlands
    PL: '🇵🇱', // Poland
    PT: '🇵🇹', // Portugal
    RO: '🇷🇴', // Romania
    SK: '🇸🇰', // Slovakia
    SI: '🇸🇮', // Slovenia
    ES: '🇪🇸', // Spain
    SE: '🇸🇪', // Sweden
    GB: '🇬🇧', // United Kingdom
    AD: '🇦🇩', // Andorra
    AE: '🇦🇪', // United Arab Emirates
    AL: '🇦🇱', // Albania
    BA: '🇧🇦', // Bosnia and Herzegovina
    CH: '🇨🇭', // Switzerland
    CR: '🇨🇷', // Costa Rica
    DO: '🇩🇴', // Dominican Republic
    GE: '🇬🇪', // Georgia
    HK: '🇭🇰', // Hong Kong
    IS: '🇮🇸', // Iceland
    LI: '🇱🇮', // Liechtenstein
    MC: '🇲🇨', // Monaco
    MD: '🇲🇩', // Moldova
    ME: '🇲🇪', // Montenegro
    MK: '🇲🇰', // North Macedonia
    NO: '🇳🇴', // Norway
    RS: '🇷🇸', // Serbia
    SM: '🇸🇲', // San Marino
    UA: '🇺🇦', // Ukraine
    VA: '🇻🇦', // Vatican City
    US: '🇺🇸', // United States
    CA: '🇨🇦', // Canada
    AU: '🇦🇺', // Australia
    NZ: '🇳🇿', // New Zealand
    JP: '🇯🇵', // Japan
    KR: '🇰🇷', // South Korea
    SG: '🇸🇬', // Singapore
    CN: '🇨🇳', // China
    IN: '🇮🇳', // India
    BR: '🇧🇷', // Brazil
    MX: '🇲🇽', // Mexico
    AR: '🇦🇷', // Argentina
    CL: '🇨🇱', // Chile
    CO: '🇨🇴', // Colombia
    IL: '🇮🇱', // Israel
    ZA: '🇿🇦', // South Africa
    AZ: '🇦🇿', // Azerbaijan
    // African country flags
    MA: '🇲🇦', // Morocco
    DZ: '🇩🇿', // Algeria
    TN: '🇹🇳', // Tunisia
    EG: '🇪🇬', // Egypt
    LY: '🇱🇾', // Libya
    SD: '🇸🇩', // Sudan
    SS: '🇸🇸', // South Sudan
    KE: '🇰🇪', // Kenya
    TZ: '🇹🇿', // Tanzania
    UG: '🇺🇬', // Uganda
    RW: '🇷🇼', // Rwanda
    BI: '🇧🇮', // Burundi
    ET: '🇪🇹', // Ethiopia
    DJ: '🇩🇯', // Djibouti
    SO: '🇸🇴', // Somalia
    ER: '🇪🇷', // Eritrea
    NG: '🇳🇬', // Nigeria
    GH: '🇬🇭', // Ghana
    CI: '🇨🇮', // Ivory Coast
    SN: '🇸🇳', // Senegal
    GM: '🇬🇲', // Gambia
    GW: '🇬🇼', // Guinea-Bissau
    GN: '🇬🇳', // Guinea
    SL: '🇸🇱', // Sierra Leone
    LR: '🇱🇷', // Liberia
    ML: '🇲🇱', // Mali
    BF: '🇧🇫', // Burkina Faso
    NE: '🇳🇪', // Niger
    TD: '🇹🇩', // Chad
    CM: '🇨🇲', // Cameroon
    GA: '🇬🇦', // Gabon
    CG: '🇨🇬', // Congo
    CD: '🇨🇩', // Democratic Republic of the Congo
    AO: '🇦🇴', // Angola
    NA: '🇳🇦', // Namibia
    BW: '🇧🇼', // Botswana
    ZW: '🇿🇼', // Zimbabwe
    MZ: '🇲🇿', // Mozambique
    MW: '🇲🇼', // Malawi
    ZM: '🇿🇲', // Zambia
    MG: '🇲🇬', // Madagascar
    SC: '🇸🇨', // Seychelles
    KM: '🇰🇲', // Comoros
    MU: '🇲🇺', // Mauritius
  };

  // Return flag emoji with country code, or just the country code if no flag found
  return countryFlags[countryCode] ? `${countryFlags[countryCode]} ${countryCode}` : countryCode;
};

const TransactionsPanel: React.FC<{ userId: string; selectedAccounts: Set<string> }> = ({
  userId,
  selectedAccounts,
}) => {
  const {
    startYear,
    startMonth,
    endYear,
    endMonth,
    transactionsLoading,
    transactions,
    setStartYear,
    setStartMonth,
    setEndYear,
    setEndMonth,
  } = useTransactions(userId, selectedAccounts);

  return (
    <TransactionsPanelContainer isVisible={selectedAccounts.size > 0}>
      <TransactionsContentWrapper>
        <FixedHeader>
          <h2>{transactions.length === 1 ? '1 Transaction' : `${transactions.length} Transactions`}</h2>
          {transactionsLoading && (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
              <Spinner size="medium" />
            </div>
          )}
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <MonthPicker
              year={startYear}
              month={startMonth}
              setYear={setStartYear}
              setMonth={setStartMonth}
            />
            <span>to</span>
            <MonthPicker
              year={endYear}
              month={endMonth}
              setYear={setEndYear}
              setMonth={setEndMonth}
            />
          </div>
        </FixedHeader>
        {selectedAccounts.size > 0 && (
          <TransactionsTable>
            <thead>
              <tr>
                <th className="date-column">Date</th>
                <th>Description</th>
                <th className="amount-column">Amount</th>
                <th className="party-column">Party</th>
                <th className="party-column">Counter Party</th>
                <th className="kind-column">Kind</th>
                <th>Transaction ID</th>
                <th className="date-column">Settled</th>
                <th className="amount-column">Balance After</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction: MonetaryTransactionInfo) => (
                <tr key={transaction.id}>
                  <td>{moment(transaction.occurred).format('YYYY-MM-DD HH:mm')}</td>
                  <td>
                    {transaction.description || transaction.remittanceInformationUnstructured || ''}
                  </td>
                  <td className="amount-column">
                    {formatAmount(transaction.mutation.cents, transaction.mutation.currency)}
                  </td>
                  <td className="party-column">
                    {transaction.party ? (
                      <>
                        {transaction.party.accountHolderName && (
                          <i>{transaction.party.accountHolderName}</i>
                        )}
                        {transaction.party.bic && (
                          <div>
                            {transaction.party.bic}{' '}
                            {transaction.party.bban && <>{transaction.party.bban}</>}
                          </div>
                        )}
                        <div>
                          {transaction.party.iban && <>{transaction.party.iban}</>}
                          {transaction.party.countryOfAccount && (
                            <> ({getCountryFlag(transaction.party.countryOfAccount)})</>
                          )}
                        </div>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td className="party-column">
                    {transaction.counterParty ? (
                      <>
                        {transaction.counterParty.accountHolderName && (
                          <i>{transaction.counterParty.accountHolderName}</i>
                        )}
                        {transaction.counterParty.bic && (
                          <div>
                            {transaction.counterParty.bic}{' '}
                            {transaction.counterParty.bban && <>{transaction.counterParty.bban}</>}
                          </div>
                        )}
                        <div>
                          {transaction.counterParty.iban && <>{transaction.counterParty.iban}</>}
                          {transaction.counterParty.countryOfAccount && (
                            <> ({getCountryFlag(transaction.counterParty.countryOfAccount)})</>
                          )}
                        </div>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td className="kind-column">{transaction.kind}</td>
                  <td>{transaction.mtid}</td>
                  <td className="date-column">
                    {transaction.settled
                      ? moment(transaction.settled).format('YYYY-MM-DD HH:mm')
                      : 'N/A'}
                  </td>
                  <td className="amount-column">
                    {transaction.balanceAfterMutation
                      ? formatAmount(transaction.balanceAfterMutation.cents, transaction.balanceAfterMutation.currency)
                      : 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </TransactionsTable>
        )}
      </TransactionsContentWrapper>
    </TransactionsPanelContainer>
  );
};

export default TransactionsPanel;
