import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import TextField from '@atlaskit/textfield';
import { getUserLogs } from '../../../../data/getUserLogs';
import { LogEntry } from '../../../../domain/logEntry';
import { String } from '../../UserDetailsView/UserDetailsProperty';
import { CodeBlock } from '@atlaskit/code';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { TabContentWrapper } from '../SidePanel.elements';
import moment from 'moment';
import Card from '../../general/Card';

const FilterContainerDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
`;

const FilterContainerItemDiv = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

interface DateRange {
  start: Date;
  end: Date;
}

const LogTab = ({ userId }: { userId: string }) => {
  const searchRef = useRef<NodeJS.Timeout | null>();
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
  const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

  const [dateRangeStart, setDateRangeStart] = useState<Date>(startDate);
  const [dateRangeEnd, setDateRangeEnd] = useState<Date>(endDate);
  const [dateRange, setDateRange] = useState<DateRange>({ start: startDate, end: endDate });
  const [searchQuery, setSearchQuery] = useState<string>('');

  const onChangeDateRangeStart = (e: string) =>
    Date.parse(e) ? setDateRangeStart(new Date(e)) : null;
  const onChangeDateRangeEnd = (e: string) => (Date.parse(e) ? setDateRangeEnd(new Date(e)) : null);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (dateRangeStart > dateRangeEnd) {
      alert('Please provide an end date that is higher than the start date');
      return;
    }
    const oneMonthInMs = 31 * 24 * 60 * 60 * 1000;
    if (dateRangeEnd.getTime() - dateRangeStart.getTime() > oneMonthInMs) {
      alert('Please choose a time span 31 days or shorter');
      return;
    }

    setDateRange({ start: dateRangeStart, end: dateRangeEnd });
  };

  const onSearch = (q: string) => {
    if (searchRef.current) clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => { setSearchQuery(q) }, 300);
  }

  return (
    <TabContentWrapper>
      <div>
        <form onSubmit={(values) => onSubmit(values)}>
          <FilterContainerDiv>
            <FilterContainerItemDiv>
              <DateTimePicker
                onChange={(value) => onChangeDateRangeStart(value)}
                value={dateRangeStart.toISOString()}
                locale={navigator.language}
              />
            </FilterContainerItemDiv>
            <FilterContainerItemDiv>
              <DateTimePicker
                onChange={(value) => onChangeDateRangeEnd(value)}
                value={dateRangeEnd.toISOString()}
                locale={navigator.language}
              />
            </FilterContainerItemDiv>
            <FilterContainerItemDiv>
              <Button type="submit" appearance="primary">
                Apply Range
              </Button>
            </FilterContainerItemDiv>
          </FilterContainerDiv>
        </form>
      </div>
      <div>
        <TextField
          placeholder="Search inside log entry"
          onChange={(event) => onSearch(event.currentTarget.value)}
        />
      </div>
      <hr />
      <LogEntriesPane userId={userId} dateRange={dateRange} searchQuery={searchQuery} />
    </TabContentWrapper>
  );
};

const LogEntriesPane = ({
  userId,
  dateRange,
  searchQuery,
}: {
  userId: string;
  dateRange: DateRange;
  searchQuery: string;
}) => {
  const [logEntries, setLogEntries] = useState<undefined | LogEntry[]>(undefined);
  const [ openEntry, setOpenEntry ] = useState<undefined | LogEntry>(undefined);

  useEffect(() => {
    async function runAsync(userId: string, dateRangeStart: Date, dateRangeEnd: Date) {
      setLogEntries(undefined);
      const result = await getUserLogs(userId, dateRangeStart, dateRangeEnd);
      // the comparator below is knowlingly implemented inversed (substracting a from b) to get a sorting in reverse order
      result.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
      setLogEntries(result);
    }
    runAsync(userId, dateRange.start, dateRange.end);
  }, [userId, dateRange.start, dateRange.end]);

  if (!logEntries) {
    return <Spinner />
  }

  if (logEntries.length === 0) {
    return "No log entries have been found.";
  }

  const filteredLogEntries =
    searchQuery.length > 0
      ? logEntries.filter((le) => {
          return le.jsonData.indexOf(searchQuery) > -1;
        })
      : logEntries;

  return (
    <>
      {filteredLogEntries.map((logEntry, index) => (
        <LogEntryItem 
          logEntry={logEntry} 
          key={logEntry.uid + index} 
          onPress={() => setOpenEntry(logEntry)}
        />
      ))}

      { openEntry && <LogEntryModal logEntry={ openEntry } onClose={() => setOpenEntry(undefined)}></LogEntryModal> }
    </>
  );
};

const makeTitle = (logEntry: LogEntry) => {
  const log = JSON.parse(logEntry.jsonData);
  const typesWithCount:{[key: string]: number} = {};
  const addCount = (t:string) => {
    if (t in typesWithCount) {
      typesWithCount[t] = typesWithCount[t] + 1;
    } else {
      typesWithCount[t] = 1;
    }
  }
  if (!('batch' in log)) return logEntry.info;
  log['batch'].forEach((b:object) => {
    if (!b) return;
    if ('activityType' in b) {
      addCount(b['activityType'] as string);
    }
    if ('eventType' in b) {
      addCount(b['eventType'] as string);
    }
  });
  return Object.keys(typesWithCount).map(t => typesWithCount[t] > 1 ? `${t} (${typesWithCount[t]})` : t).join(', ');
}

const LogEntryItem = ({ logEntry, onPress }: { logEntry: LogEntry, onPress: () => void }) => {
  const m = moment(logEntry.timestamp);
  return (
    <div style={{padding: '2px 0', cursor: 'pointer'}} onClick={onPress}>
      <Card>
        <div>
          <code style={{color: '#333', fontWeight: 'bold'}}>{m.format('Do HH:mm:ss')}</code> {makeTitle(logEntry)}
        </div>
      </Card>
    </div>
  );
};

const LogEntryModal = ({ logEntry, onClose }: { logEntry: LogEntry, onClose: () => void }) => {
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen])

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle>{makeTitle(logEntry)}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <h4>{(moment(logEntry.timestamp)).format('DD-MM-YYYY HH:mm:ss')}</h4>
              <table>
                <tbody>
                  <tr>
                    <td width="20%">
                      <String
                        title="Service"
                        content={logEntry.svc ? logEntry.svc : 'unknown'}
                      />
                    </td>
                    <td width="70%">
                      <String
                        title="Operation"
                        content={logEntry.op ? logEntry.op : 'unknown'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <CodeBlock language='json' shouldWrapLongLines={true} text={JSON.stringify(JSON.parse(logEntry.jsonData), null, 2)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );

}

export default LogTab;
