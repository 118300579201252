import { API, graphqlOperation } from 'aws-amplify';
import { getAllFlowsQuery } from './query';
import { UserFlowItem } from '../domain/userFlowItem';

export async function getAllFlows(userId: string): Promise<UserFlowItem[]> {
  const result = await API.graphql(
    graphqlOperation(getAllFlowsQuery, {
      userId,
    })
  );
  return result.data.getAllFlows;
}
