export interface Party {
  bic: string
  bban: string
  iban: string
  nonSepa: string
  accountHolderName: string
  countryOfAccount: string
  accountKind: string
}

export interface MonetaryTransactionInfo {
  id: string,
  mtid: string
  uuid: string
  kind: string
  description: string
  remittanceInformationUnstructured: string
  ingested: string
  expiresAt: string
  mutation: {
    cents: number,
    currency: string
  }
  balanceAfterMutation?: {
    cents: number,
    currency: string
  }
  forex: {
    cents: number,
    currency: string
  }
  occurred: string
  settled: string
  party: Party
  counterParty: Party
  status: string
  bankRef: string
}

export interface HighRiskTransactions {
  year: number
  month: number
  week: number
  alertType: string
  totalAmount: {
    cents: number,
    currency: string
  }
  transactions: MonetaryTransactionInfo[]
}

export interface HighRiskTransactionReview {
  id: string
  status: string
  note: string
}

export const formatAmount = (cents: number | null, currency?: string) => {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: currency || 'EUR',
  });

  if (cents === null) {
    return '???';
  }
  if (typeof cents !== 'number') {
    return '???';
  }
  return formatter.format(cents / 100);
};
