import React, { useState, useEffect } from 'react';
import { KYCStatus } from '../../domain/kycStatus';
import { FlexDiv } from '../shared';
import { useQuery } from '@apollo/client';
import { listPendingReviewsQuery } from '../../data/query';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import DynamicTable from '@atlaskit/dynamic-table';
import { Box } from '@atlaskit/primitives';
import { TableRow } from './types';
import Card from '../shared/general/Card';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const TableCell = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const head = {
  cells: [
    {
      key: 'name',
      content: 'Name',
    },
  ],
};

interface PendindReviewTypes {
  listPendingReviews: KYCStatus[];
}

function ReviewPage() {
  const { data, loading, error } = useQuery<PendindReviewTypes>(listPendingReviewsQuery);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const rows = data.listPendingReviews.map((review) => ({
        key: `${review.userId}`,
        cells: [
          {
            key: 'name',
            content: (
              <TableCell>
                {review.userName.given_name} {review.userName.family_name}
              </TableCell>
            ),
          },
        ],
      }));

      setTableData(rows);
    }
  }, [data]);

  if (loading) {
    return (
      <FlexDiv align="center" justify="center" style={{ height: '400px' }}>
        <Spinner />
      </FlexDiv>
    );
  }

  if (error || !data) {
    return <SectionMessage title={'Error fetching data'} children={undefined} />;
  }

  const rowClickHandler = (id: string): void => {
    if (!id) return;
    navigate(id);
  };

  const extendRows = (rows: TableRow[], onClick: (id: string) => void) => {
    return rows.map((row) => ({
      ...row,
      onClick: () => onClick(row.key),
    }));
  };

  return (
    <Card>
      <Tabs id={'tabs'}>
        <TabList>
          <Tab>Unreviewed</Tab>
        </TabList>
        <TabPanel>
          <Box paddingBlock="space.200">
            <DynamicTable
              isFixedSize
              head={head}
              rows={extendRows(tableData, rowClickHandler)}
              rowsPerPage={10}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={loading}
            />
          </Box>
        </TabPanel>
      </Tabs>
      <Outlet />
    </Card>
  );
}

export default ReviewPage;
