import React from 'react';
import { BankAccountState } from '../../../../domain/bankAccount';
import {
  PanelCardContainer,
  PanelCardHeader,
  PanelCardTitle,
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  TabContentWrapper,
  PanelCardItemWarn,
} from '../SidePanel.elements';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { VerificationStatus } from '../../../../domain/verificationStatus';
import ManualApprovalButton from './ManualApprovalButton';
import { hasAnsweredWhatsRequired } from '../../../../domain/answer';
interface BanksTabProps {
  userId: string;
  bankAccounts: BankAccountState[];
}


const BanksTab: React.FC<BanksTabProps> = ({ bankAccounts, userId }) => {
  return (
    <TabContentWrapper>
      {bankAccounts.length > 0 ? (
        bankAccounts.map((account, idx) => {
          const isBusinessAccount =
            account.answers.isBusinessAccount.hasValue &&
            account.answers.isBusinessAccount.value.toString() === 'true';
          return (
            <PanelCardContainer key={idx}>
              <PanelCardHeader>
                <PanelCardTitle>{account.verificationStatus}</PanelCardTitle>
                {account.verificationStatus === VerificationStatus.VERIFIED ? (
                    <CheckCircleOutlineIcon primaryColor="#1890FF" label="check" size="medium" />
                ) : (
                  <CrossCircleIcon primaryColor="#FF4D4F" label="cross" size="small" />
                )}
              </PanelCardHeader>
              {!hasAnsweredWhatsRequired(account.answers.kvkNumber) &&
                <PanelCardItemWarn>User must enter Company Registration Number</PanelCardItemWarn>
              }
              <PanelCardItem>
                <PanelCardItemName>Bank account name</PanelCardItemName>
                <PanelCardItemValue>{account.bankAccountHolderNames.join()}</PanelCardItemValue>
              </PanelCardItem>
              <PanelCardItem>
                <PanelCardItemName>Bank user ID</PanelCardItemName>
                <PanelCardItemValue>{account.bankAccountId || '-'}</PanelCardItemValue>
              </PanelCardItem>
              <PanelCardItem>
                <PanelCardItemName>Bank account type</PanelCardItemName>
                <PanelCardItemValue>
                  {isBusinessAccount
                    ? 'Business'
                    : 'Personal'}
                </PanelCardItemValue>
              </PanelCardItem>
              {isBusinessAccount && account.verificationStatus === VerificationStatus.UNVERIFIED &&
               <ManualApprovalButton bankAccountId={account.bankAccountId} userId={userId}/>}
            </PanelCardContainer>
          );
        })
      ) : (
        <div>No bank account added</div>
      )}
    </TabContentWrapper>
  );
};

export default BanksTab;
