import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  bankAccountReviewMutation,
  naturalPersonReviewMutation,
} from '../../../../../../data/submitReview';
import { getUserWithDocuments } from '../../../../../../data/query';
import { BankAccountReviewAction, NaturalPersonReviewAction, ReviewResult } from '../../../../../../domain/actions';
import { DefaultNote } from '../defaultNotes';
import { Review } from '../../../../../../domain/review';

interface ReviewParams {
  userId?: string;
  bankAccountId?: string;
  review?: Review;
  actionType: BankAccountReviewAction['type'] | NaturalPersonReviewAction['type'];
  defaultNote: DefaultNote;
}

type DefaultParams = {
  note: string;
  reviewResult: ReviewResult;
};

type SubmitBankAccountReview = Omit<ReviewParams, 'defaultNote'> & DefaultParams;

type SubmitNaturalPersonReview = Omit<ReviewParams, 'defaultNote' | 'bankAccountId'> &
  DefaultParams;

export default function useReviewCardState({
  userId,
  bankAccountId,
  actionType,
  defaultNote,
  review,
}: ReviewParams) {
  const [reviewState, setReviewState] = useState<'APPROVAL' | 'REJECTION'>('APPROVAL');
  const [isReviewed, setIsReviewed] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<'Approval reason' | 'Rejection reason'>(
    'Approval reason'
  );

  const [defaultReviewNote, setDefaultReviewNote] = useState<string>('');
  const [reviewNote, setReviewNote] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [value, setValue] = useState<number>(1);

  const [AddNaturalPersonReview] = useMutation<SubmitNaturalPersonReview>(
    naturalPersonReviewMutation,
    {
      variables: {
        userId,
        actionType,
        reviewResult: reviewState === 'APPROVAL' ? ReviewResult.APPROVE : ReviewResult.REJECT,
        note:
          value === 1
            ? reviewState === 'APPROVAL'
              ? defaultNote.APPROVE
              : defaultNote.REJECT
            : note,
      },
      refetchQueries: [{ query: getUserWithDocuments, variables: { userId } }],
    }
  );
  const [addBankAccountReview] = useMutation<SubmitBankAccountReview>(
    bankAccountReviewMutation,
    {
      variables: {
        userId,
        bankAccountId,
        actionType,
        reviewResult: reviewState === 'APPROVAL' ? ReviewResult.APPROVE : ReviewResult.REJECT,
        note:
          value === 1
            ? reviewState === 'APPROVAL'
              ? defaultNote.APPROVE
              : defaultNote.REJECT
            : note,
      },
      refetchQueries: [{ query: getUserWithDocuments, variables: { userId } }],
    }
  );

  useEffect(() => {
    if (review?.hasValue && review.value) {
      setReviewState(review?.value.reviewResult === 'APPROVE' ? 'APPROVAL' : 'REJECTION');
      setReviewNote(review?.value.note);
      setIsReviewed(true);
    }
  }, [review]);

  const onClickHandler = (action: 'REJECT' | 'APPROVE'): void => {
    if (action === 'APPROVE') {
      setReviewState('APPROVAL');
      setModalTitle('Approval reason');
      setDefaultReviewNote(defaultNote.APPROVE);
    } else {
      setReviewState('REJECTION');
      setModalTitle('Rejection reason');
      setDefaultReviewNote(defaultNote.REJECT);
    }
    setModalVisible(!modalVisible);
  };

  const handleCancel = (): void => {
    setModalVisible(!modalVisible);
  };

  const handleOk = (): void => {
    setModalVisible(!modalVisible);
    bankAccountId ? addBankAccountReview() : AddNaturalPersonReview();
  };

  return {
    modalVisible,
    setModalVisible,
    reviewState,
    handleCancel,
    handleOk,
    modalTitle,
    note,
    defaultReviewNote,
    reviewNote,
    setNote,
    value,
    setValue,
    onClickHandler,
    isReviewed,
  };
}
