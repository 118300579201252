import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { Auth } from 'aws-amplify';

import configure from '../amplify';
configure();

const STAGE = process.env.REACT_APP_STAGE;

const httpLink = new BatchHttpLink({
  uri: STAGE
    ? `https://${STAGE}-api.flowour.money/crm/graphql`
    : process.env.REACT_APP_GRAPHQL_ENDPOINT,
  batchMax: 10, // Maximum number of queries to batch
  batchInterval: 50, // Milliseconds to wait before sending a batch
});

const authLink = setContext(async () => {
  const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
  };
});

export const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getAllTransactions: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
});
