import React from 'react';
import {
  PanelCardButton,
  PanelCardContainer,
  PanelCardControls,
  PanelCardHeader,
  PanelCardItem,
  PanelCardItemLink,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardTitle,
} from '../../SidePanel.elements';
// import ReviewedCard from './ReviewedCard';
import { PanelCardStandardProps } from '../types';
import { DefaultNotes } from './defaultNotes';
import { useReviewCardState } from './hooks';
import { IDINUserInfo } from '../../../../../domain/signicat';
import { OptionsPropType } from '@atlaskit/radio/types';
import { ReviewModal } from '..';

interface ReviewAccountHolderNameProps extends PanelCardStandardProps {
  isBusinessAccount: boolean | 'true' | 'false';
  IDINUserInfo: IDINUserInfo | null;
  bankAccountHolderNames: string[];
}

const ReviewAccountHolderName: React.FC<ReviewAccountHolderNameProps> = ({
  userId,
  bankAccountId,
  review,
  isBusinessAccount,
  name,
  IDINUserInfo,
  bankAccountHolderNames,
  kvkInfo,
}) => {
  const {
    modalVisible,
    // reviewState,
    handleCancel,
    handleOk,
    modalTitle,
    isReviewed,
    note,
    setNote,
    defaultReviewNote,
    // reviewNote,
    value,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    bankAccountId,
    actionType: 'REVIEW_BANK_ACCOUNT_HOLDER_NAMES',
    defaultNote: DefaultNotes.ReviewAccountHolderName,
    review,
  });

  const options: OptionsPropType = [
    { name: 'default', value: '1', label: defaultReviewNote },
    { name: 'other', value: '2', label: 'Other (O)' },
  ];

  if (!review?.isRequired) {
    return null;
  }

  if (isReviewed) {
    return null; // An empty dashboard is more favorable for the reviewer
    // return (
    //   <ReviewedCard
    //     title="Review Bank Account Holder's Name"
    //     state={reviewState}
    //     note={reviewNote}
    //   />
    // );
  }

  return (
    <>
      <PanelCardContainer>
        <PanelCardHeader>
          <PanelCardTitle>Review Account Holder Name</PanelCardTitle>
        </PanelCardHeader>
        <PanelCardItem>
          <PanelCardItemName>Flow Name</PanelCardItemName>
          <PanelCardItemValue>{name}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>iDin Name</PanelCardItemName>
          <PanelCardItemValue>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
        </PanelCardItem>
        {isBusinessAccount === 'true' && (
          <>
            <PanelCardSeparator />
            <PanelCardItem>
              <PanelCardItemName>KVK Business Name</PanelCardItemName>
              <PanelCardItemValue isRed>
                {kvkInfo?.tradeNames?.businessName || '-'}
              </PanelCardItemValue>
            </PanelCardItem>
            <PanelCardItem>
              <PanelCardItemName>Description</PanelCardItemName>
              <PanelCardItemValue isRed>
                {kvkInfo?.businessActivities?.map(a => a.sbiCodeDescription).join(', ') || '-'}
              </PanelCardItemValue>
            </PanelCardItem>
            <PanelCardItem>
              <PanelCardItemName>Website</PanelCardItemName>
              <PanelCardItemValue>
                {kvkInfo?.websites?.map((site) => (
                  <PanelCardItemLink href={`http://${site}`} target="_blank">
                    {site}
                  </PanelCardItemLink>
                )) || '-'}
              </PanelCardItemValue>
            </PanelCardItem>
          </>
        )}
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>Bank account holder name</PanelCardItemName>
          <PanelCardItemValue isRed>{bankAccountHolderNames.join() || '-'}</PanelCardItemValue>
        </PanelCardItem>
        <PanelCardItem>
          <PanelCardItemName>Bank account type</PanelCardItemName>
          <PanelCardItemValue>
            {isBusinessAccount === 'true' ? 'Business' : 'Personal'}
          </PanelCardItemValue>
        </PanelCardItem>
        <PanelCardControls>
          <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>
            Approve
          </PanelCardButton>
          <PanelCardButton onClick={() => onClickHandler('REJECT')}>Reject</PanelCardButton>
        </PanelCardControls>
      </PanelCardContainer>

      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        optionValue={value.toString()}
        options={options}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </>
  );
};

export default ReviewAccountHolderName;
