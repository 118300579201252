import { useState, useEffect } from 'react';
import { formatDistance } from 'date-fns';
// import mime from 'mime-types';
import { decryptToBase64String } from './encryption';
import { VerificationDocument } from './domain/verificationDocument';

export function getDaysAgo(date: string) {
  return formatDistance(new Date(date), new Date(), { addSuffix: true });
}

export async function retrieveDocumentContents(document: VerificationDocument): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch(document.url)
      .then((response) => checkStatus(response) && response.text())
      .then((encryptedString: string) => {
        resolve(decryptToBase64String(encryptedString, document.key));
      })
      .catch((err) => {
        reject(err);
      });
    function checkStatus(response: Response) {
      if (!response.ok) {
        throw new Error(`HTTP ${response.status} - ${response.statusText}`);
      }
      return response;
    }
  });
}

function mimetype(view: DataView) {
  const header = [view.getUint8(0), view.getUint8(1), view.getUint8(2), view.getUint8(3)]
    .map((x) => x.toString(16))
    .join('')
    .toUpperCase();

  switch (header) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
      return 'image/jpeg';
    case '25504446':
      return 'application/pdf';
    case '504B0304':
      return 'application/zip';
    default:
      return 'application/octet-stream';
  }
}

export async function base64ToBlob(base64str: string): Promise<Blob> {
  const url = `data:application/octet-stream;base64,${base64str}`;
  const buf = await fetch(url).then((res) => res.arrayBuffer());
  const type = mimetype(new DataView(buf));
  return new Blob([buf], { type });
}

async function retrieveDocumentBlobs(documents: VerificationDocument[]): Promise<Blob[]> {
  const docs = documents.map((d) => retrieveDocumentContents(d).then((d) => base64ToBlob(d)));
  return Promise.all(docs);
}

export function useDocumentBlobs(documents: VerificationDocument[]): { documentBlobs: Blob[] } {
  const [allDocuments] = useState<VerificationDocument[]>(documents);
  const [documentBlobs, setDocumentBlobs] = useState<Blob[]>([]);

  useEffect(() => {
    const fetchBlobs = async () => {
      const blobs = await retrieveDocumentBlobs(allDocuments);
      setDocumentBlobs(blobs);
    };
    fetchBlobs();
  }, [allDocuments]);

  return { documentBlobs };
}

export const detectMobile = (): boolean => {
  let isMobile = false;
  // device detection
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  }
  return isMobile;
};
