import Select from '@atlaskit/select';
import React from 'react';
import moment from 'moment';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const generateYears = (startYear: number = 2020) => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
};

interface MonthPickerProps {
  year: number;
  month: number;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
}

const MonthPicker = (props: MonthPickerProps) => {
  const { year, setYear, month, setMonth } = props;
  // Create options for months and years
  const monthOptions = months.map((month, index) => ({
    label: month,
    value: index,
  }));

  const yearOptions = generateYears().map((year) => ({
    label: String(year),
    value: year,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Select
        styles={{
          container: (base) => ({
            ...base,
            width: '128px',
          }),
        }}
        options={monthOptions}
        value={monthOptions.find((option) => option.value === month)}
        onChange={(selectedOption) => setMonth(selectedOption?.value ?? moment().month())}
      />
      <Select
        styles={{
          container: (base) => ({
            ...base,
            width: '100px',
          }),
        }}
        options={yearOptions}
        value={yearOptions.find((option) => option.value === year)}
        onChange={(selectedOption) => setYear(selectedOption?.value ?? moment().year())}
      />
    </div>
  );
};

export default MonthPicker;
