import { QueryResult, useQuery } from '@apollo/client';
import { getAllMonetaryAccountsQuery } from './query';

export interface MonetaryAccount {
  id: string;
  bankAccountId: string;
  createdAt: string;
  updatedAt: string;
  iban?: string;
  displayName: string;
  legalName: string;
  kind: 'UNKNOWN' | 'PERSONAL' | 'JOINT' | 'BUSINESS'; // see fullAccountKind
  accountState: 'LOCKED' | 'ACTIVE' | 'DISCONNECTED' | 'REPLACED' | 'REMOVED';
  balance?: number;
  currency: string;
  onlyWithSCA: boolean;
}

export function useMonetaryAccounts(userId: string): QueryResult<{ getUserMonetaryAccounts: MonetaryAccount[] }> {
  return useQuery(getAllMonetaryAccountsQuery, {
    variables: { userId },
  });
}
