import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import Button, { ButtonGroup } from '@atlaskit/button';
import Tag from '@atlaskit/tag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle-outline';
import { CollapsibleTransactionCard } from './CollapsibleTransactionCard';
import { useMutation, useQuery } from '@apollo/client';
import {
  formatAmount,
  HighRiskTransactionReview,
  HighRiskTransactions,
} from '../../data/getHighRiskTransactions';
import {
  getHighRiskTransactionReviewsQuery,
  getHighRiskTransactionsByMonthQuery,
} from '../../data/query';
import {
  highRiskTransactionsReviewMutation,
  HighRiskTransactionsReviewStatus,
} from '../../data/submitReview';
import TextArea from '@atlaskit/textarea';
import Card from '../shared/general/Card';
import { PartyDetails } from './PartyDetails';
import { TransactionDescription } from './TransactionDescription';
import { Timeline } from './Timeline';
import { CounterPartyDetails } from './CounterPartyDetails';
import { Outlet } from 'react-router-dom';
import MonthPicker from '../shared/general/MonthPicker';
import Spinner from '@atlaskit/spinner';
import Select from '@atlaskit/select';
import { useCrmUser } from '../../data/compliance';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
`;

const now = new Date();

const FixedCard = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const HorizontalCardLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: start;
  width: 100%;

  @media (min-width: 1200px) {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
  }
`;

const filterOptions = [
  { label: 'Unreviewed', value: 'UNREVIEWED' },
  { label: 'Reviewing', value: 'REVIEWING' },
  { label: 'BGTO investigating', value: 'BGTO_INVESTIGATING' },
  { label: 'False positive', value: 'FALSE_POSITIVE' },
  { label: 'Reported', value: 'REPORTED' },
];

function TransactionsPage() {
  const notes = useRef<{ [key: string]: string }>({});
  const { crmUser: user } = useCrmUser();

  const [reviewTransaction, { loading: reviewTransactionLoading }] = useMutation(
    highRiskTransactionsReviewMutation
  );
  function submitReview(
    status: HighRiskTransactionsReviewStatus,
    id: string,
    { year, month }: { year: number; month: number }
  ) {
    reviewTransaction({
      variables: { status, year, month, id, note: notes.current[id] ?? '' },
    }).then(refetchReviews);
  }

  const [reviewStatusFilters, setReviewStatusFilters] = useState<Set<HighRiskTransactionsReviewStatus>>(new Set());
  const [year, setYear] = useState(now.getFullYear() - 1);
  const [month, setMonth] = useState(now.getMonth() - 2);

  const { data, loading, fetchMore } = useQuery<{
    getHighRiskTransactionsByMonth: HighRiskTransactions[];
  }>(getHighRiskTransactionsByMonthQuery, {
    variables: { year, month: month + 1 },
    notifyOnNetworkStatusChange: true,
  });
  const { data: reviewsData, refetch: refetchReviews } = useQuery<{
    getHighRiskTransactionReviews: HighRiskTransactionReview[];
  }>(getHighRiskTransactionReviewsQuery, {
    variables: { year, month: month + 1 },
    notifyOnNetworkStatusChange: true,
  });

  function loadPrevMonth() {
    const y = month > 0 ? year : year - 1;
    const m = month > 0 ? month - 1 : 11;
    setYear(y);
    setMonth(m);
    fetchMore({ variables: { year: y, month: m + 1 } });
  }

  function loadNextMonth() {
    const y = month < 11 ? year : year + 1;
    const m = month < 11 ? month + 1 : 0;
    setYear(y);
    setMonth(m);
    fetchMore({ variables: { year: y, month: m + 1 } });
  }

  const getCurrentDate = () => {
    const now = new Date();
    return now.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const filteredData = data?.getHighRiskTransactionsByMonth.filter((d) => {
    if (reviewStatusFilters.size === 0) return true;

    return d.transactions.some((transaction) => {
      const review = reviewsData?.getHighRiskTransactionReviews.find(
        (r) => r.id === transaction.id
      );
      return reviewStatusFilters.has(
        review?.status ? (review.status as HighRiskTransactionsReviewStatus) : 'UNREVIEWED'
      );
    });
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <FixedCard style={{ minWidth: '580px', margin: '0 auto 0 0' }}>
        <Card>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <h2 style={{ minWidth: '140px', marginTop: 0, marginRight: 'auto', display: 'inline-block' }}>
              {!filteredData ? 'Loading...' : filteredData?.length === 1 ? '1 alert' : `${filteredData?.length ?? 0} alerts`}
              {loading && <div style={{ display: 'inline-block', marginLeft: '16px' }}><Spinner size="medium" /></div>}
            </h2>
            <div style={{ marginLeft: '16px' }}>
              <Button onClick={loadPrevMonth} isDisabled={new Date(year, month - 1) < new Date(2020, 0)}>← Vorige</Button>
            </div>
            <div style={{ fontWeight: 600 }}>
              <MonthPicker year={year} month={month} setYear={setYear} setMonth={setMonth} />
            </div>
            <div style={{ marginRight: '16px' }}>
              <Button onClick={loadNextMonth} isDisabled={new Date(year, month + 1) > now}>Volgende →</Button>
            </div>
            <Select
              isMulti
              value={
                reviewStatusFilters.size === 0
                  ? null
                  : filterOptions.filter(
                      (option) =>
                        option.value === null ||
                        reviewStatusFilters.has(option.value as HighRiskTransactionsReviewStatus)
                    )
              }
              onChange={(selectedOptions) => {
                const newFilters = new Set(
                  selectedOptions
                    .map(option => option.value)
                    .filter((value): value is HighRiskTransactionsReviewStatus => value !== null)
                );
                setReviewStatusFilters(newFilters);
              }}
              options={filterOptions}
            />
          </div>
        </Card>
      </FixedCard>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 128px)',
          }}
        >
          <Spinner size="large" />
        </div>
      )}
      {filteredData?.map((t) => (
        <div key={t.totalAmount.cents + t.transactions[0].id} style={{ marginBottom: '32px' }}>
          <h3>{t.alertType}</h3>
          <div style={{ display: 'flex', gap: '4px', marginLeft: -4, marginBottom: '16px' }}>
            <Tag
              text={`${t.month} / ${t.year} – Week: ${t.week}`}
              appearance="rounded"
              color="blueLight"
              isRemovable={false}
            />
            <Tag
              text={`Sum total: ${formatAmount(t.totalAmount.cents, t.totalAmount.currency)}`}
              appearance="rounded"
              color="grey"
              isRemovable={false}
            />
          </div>
          <CardContainer>
            {t.transactions.map((transaction) => {
              const review = reviewsData?.getHighRiskTransactionReviews.find(
                (r) => r.id === transaction.id
              );
              if (
                reviewStatusFilters.size > 0 &&
                !reviewStatusFilters.has(
                  review?.status
                    ? (review.status as HighRiskTransactionsReviewStatus)
                    : 'UNREVIEWED'
                )
              ) {
                return null;
              }
              return (
                <CollapsibleTransactionCard
                  key={transaction.id}
                  collapsed={review ? review.status === 'FALSE_POSITIVE' || review.status === 'REPORTED' : undefined}
                  alwaysVisibleContent={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        width: '100%',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        {'mutation' in transaction && (
                          <AmountDisplay>
                            {formatAmount(transaction.mutation?.cents, transaction.mutation?.currency)}
                          </AmountDisplay>
                        )}
                        <ButtonGroup>
                          <Button
                            isDisabled={loading || reviewTransactionLoading}
                            iconBefore={
                              review?.status === 'REVIEWING' ? (
                                <CheckCircleIcon label="" />
                              ) : undefined
                            }
                            appearance={review?.status === 'REVIEWING' ? 'primary' : 'default'}
                            onClick={() => submitReview('REVIEWING', transaction.id, t)}
                          >
                            Reviewing
                          </Button>
                          <Button
                            isDisabled={loading || reviewTransactionLoading}
                            iconBefore={
                              review?.status === 'FALSE_POSITIVE' ? (
                                <CheckCircleIcon primaryColor='green' label="" />
                              ) : undefined
                            }
                            appearance={review?.status === 'FALSE_POSITIVE' ? 'subtle' : 'default'}
                            onClick={() => submitReview('FALSE_POSITIVE', transaction.id, t)}
                          >
                            False positive
                          </Button>
                          <Button
                            isDisabled={loading || reviewTransactionLoading}
                            iconBefore={
                              review?.status === 'BGTO_INVESTIGATING' ? (
                                <CheckCircleIcon label="" />
                              ) : undefined
                            }
                            appearance={review?.status === 'BGTO_INVESTIGATING' ? 'warning' : 'default'}
                            onClick={() => submitReview('BGTO_INVESTIGATING', transaction.id, t)}
                          >
                            BGTO investigating
                          </Button>
                          <Button
                            isDisabled={loading || reviewTransactionLoading}
                            iconBefore={
                              review?.status === 'REPORTED' ? (
                                <CheckCircleIcon label="" />
                              ) : undefined
                            }
                            appearance={review?.status === 'REPORTED' ? 'danger' : 'default'}
                            onClick={() => submitReview('REPORTED', transaction.id, t)}
                          >
                            Reported
                          </Button>
                        </ButtonGroup>
                      </div>
                      <div>
                        {' '}
                        <TextArea
                          onPointerEnterCapture={null}
                          onPointerLeaveCapture={null}          
                          isDisabled={loading || reviewTransactionLoading}
                          onChange={(e) => (notes.current[transaction.id] = e.target.value)}
                          defaultValue={
                            review?.note ??
                            `${user?.given_name} ${user?.family_name} - ${getCurrentDate()} `
                          }
                          style={{ width: '100%', marginBottom: '8px' }}
                        />
                      </div>
                    </div>
                  }
                  // status={review?.status as HighRiskTransactionsReviewStatus}
                >
                  <HorizontalCardLayout>
                    <div>
                      <Timeline transaction={transaction} />
                      {'description' in transaction && (
                        <TransactionDescription transaction={transaction} />
                      )}
                    </div>

                    {'party' in transaction && <PartyDetails party={transaction.party} />}

                    {'counterParty' in transaction && (
                      <CounterPartyDetails counterParty={transaction.counterParty} />
                    )}
                  </HorizontalCardLayout>
                </CollapsibleTransactionCard>
              );
            })}
          </CardContainer>
        </div>
      ))}
      {/* <LoadingButton
        isLoading={loading || (year_month.year === 2020 && year_month.month === 1)}
        onClick={loadPrevMonth}
      >
        Load more
      </LoadingButton> */}
      <Outlet />
    </div>
  );
}

export default TransactionsPage;
