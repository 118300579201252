import React from 'react';

interface StringPropertyProps {
  title: string;
  content: string;
}

export const String = ({ title, content }: StringPropertyProps) => {
  return (
    <p key={`${title}-${content}`}>
      <strong>{title}:</strong>
      <br />
      {content}
    </p>
  );
};