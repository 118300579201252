import { styled } from 'styled-components';

export const CardSection = styled.div`
  margin-bottom: 16px;
`;

export const SectionTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  color: #6b778c;
  border-bottom: 3px solid #e0e0e0;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const Label = styled.div`
  font-weight: 500;
  color: #6b778c;
`;
