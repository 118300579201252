import React from 'react';
import { Auth } from 'aws-amplify';
import { SideNavContainer } from './SideNav.elements';
import FlowLogo from './FlowLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import SearchIcon from '@atlaskit/icon/glyph/search'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import BillingIcon from '@atlaskit/icon/glyph/billing'
import { useCrmUser } from '../../../data/compliance';

const SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const signOut = () => {
    Auth.signOut().catch((err) => console.error('error signing out: ', err));
  };

  const { isCompliance } = useCrmUser();

  return (
    <SideNavContainer>
      <FlowLogo />
      <MenuGroup>
      <Section>
        {/* <ButtonItem 
          iconBefore={ <DashboardIcon label='' /> }
          isSelected={location.pathname.startsWith('/dashboard')}
          onClick={() => navigate('/dashboard')}>
          Dashboard
        </ButtonItem> */}
        <ButtonItem
          iconBefore={ <DocumentsIcon label='' /> }
          isSelected={location.pathname.startsWith('/kyc-review')}
          onClick={() => navigate('/kyc-review')}>
          KYC Review
        </ButtonItem>
      </Section>
      <Section>
        <ButtonItem
          iconBefore={ <SearchIcon label='' /> }
          isSelected={location.pathname.startsWith('/users')}
          onClick={() => navigate('/users')}
        >
          Search
        </ButtonItem>
      </Section>
      {isCompliance && (
        <Section>
          <ButtonItem
            iconBefore={ <BillingIcon label='' /> }
            isSelected={location.pathname.startsWith('/transactions')}
            onClick={() => navigate('/transactions')}
          >
            Transactions
          </ButtonItem>
        </Section>
      )}
      <Section hasSeparator>
        <ButtonItem 
        iconBefore={ <SignOutIcon label='' /> }
          onClick={signOut}
        >Log out</ButtonItem>
      </Section>
    </MenuGroup>
    </SideNavContainer>
  );
};

export default SideNav;
