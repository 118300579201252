import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { User } from '../../domain/user';
import { useLazyQuery } from '@apollo/client';
import { searchUsersQuery } from '../../data/query';
import styled from 'styled-components';
import { Stack } from '@atlaskit/primitives';
import { SearchBar } from './SearchBar';
import { SelectOption, TableRow } from './types';
import DynamicTable from '@atlaskit/dynamic-table';
import EmptyState from '@atlaskit/empty-state';
import Button from '@atlaskit/button';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Flag from '@atlaskit/flag';
import Heading from '@atlaskit/heading';
import { Outlet, useNavigate } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  background-color: white;
  border-left: 1px;
  border-color: blue;
`;

const TableContainer = styled.div`
  background-color: white;
  margin-left: -20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  margin-right: -20px;
  padding: 16px;
`;

const TableCell = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const selectOptions: SelectOption[] = [
  { id: nanoid(), label: 'Name', value: 'name' },
  { id: nanoid(), label: 'Family name', value: 'family_name' },
  { id: nanoid(), label: 'Email', value: 'email' },
  { id: nanoid(), label: 'Phonenumber', value: 'phone_number' },
  { id: nanoid(), label: 'ID', value: 'sub' },
];

interface SearchUserTypes {
  searchUsers: Partial<User>[];
}

const head = {
  cells: [
    {
      key: 'given_name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'email',
      content: 'Email',
      isSortable: true,
    },
  ],
};

const SearchPage: React.FC = () => {
  const focusRef = useRef<HTMLInputElement>(null);
  const [selectedSearchField, setSelectedSearchField] = useState<string>(selectOptions[2].value);
  const [searchText, setSearchText] = useState<string>('');
  const [users, setUsers] = useState<TableRow[]>([]);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [searchUsers, { data, loading, error }] = useLazyQuery<SearchUserTypes>(searchUsersQuery);

  useEffect(() => {
    if (data) {
      const rows = data.searchUsers.map((user: Partial<User>) => ({
        key: `${user.id}`,
        cells: [
          {
            key: 'given_name',
            content: <TableCell>{user.given_name}</TableCell>,
          },
          {
            key: 'email',
            content: user.email,
          },
        ],
      }));

      setUsers(rows);
      if (data.searchUsers.length === 0)
        alertVibisilityHandler('No user found with provided params.');
    }
  }, [data]);

  const extendRows = (rows: TableRow[], onClick: (id: string) => void) => {
    return rows.map((row) => ({
      ...row,
      onClick: () => onClick(row.key),
    }));
  };

  const alertVibisilityHandler = (message: string): void => {
    setVisibleAlert(true);
    setAlertMessage(message);
  };

  const clearAlert = (): void => {
    setVisibleAlert(false);
    setAlertMessage('');
  };

  const onSelectChangeHandler = (value: string) => {
    setSelectedSearchField(value);
  };

  const onSearch = () => {
    clearAlert();
    if (searchText.length > 0) {
      searchUsers({
        variables: { field: selectedSearchField, searchTerm: searchText },
      });
    } else {
      alertVibisilityHandler('Please enter text to search');
    }
  };

  const navigate = useNavigate();
  const rowClickHandler = (id: string): void => {
    if (id) {
      navigate(id);
    }
  };

  if (!data && error) {
    alertVibisilityHandler(error.message);
  }

  const setSearchTextValue = (value: string) => {
    setSearchText(value);
  };

  const renderSearchBar = () => {
    return (
      <SearchBar
        isLoading={loading}
        search={onSearch}
        setSelectValue={onSelectChangeHandler}
        focusRef={focusRef}
        onFocus={clearAlert}
        setSearchValue={setSearchTextValue}
        selectOptions={selectOptions}
        searchValue={searchText}
      />
    );
  };

  const focusChild = () => {
    focusRef.current && focusRef.current.focus();
  };

  const renderEmptyState = () => {
    if (users.length === 0 && !visibleAlert) {
      return (
        <EmptyState
          header="Search Users"
          description="Get started by searching for users in the search bar above. You can search by name, family
name, email, phonenumber or ID."
          primaryAction={
            <Button appearance="primary" onClick={() => focusChild()}>
              Start Search
            </Button>
          }
        />
      );
    }
  };

  return (
    <>
      <Container>{renderSearchBar()}</Container>
      <Stack space="space.500">
        {visibleAlert && (
          <Flag
            appearance="normal"
            icon={<InfoIcon label="Info" />}
            id="info"
            key="info"
            title={alertMessage}
          />
        )}

        {users.length > 0 ? (
          <TableContainer>
            <Stack space="space.200">
              <Heading level={'h500'}>Results ({users.length})</Heading>
              <DynamicTable
                head={head}
                rows={extendRows(users, rowClickHandler)}
                rowsPerPage={20}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={loading}
              />
            </Stack>
          </TableContainer>
        ) : (
          renderEmptyState()
        )}
      </Stack>
      <Outlet />
    </>
  );
};

export default SearchPage;
