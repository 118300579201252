export enum VerificationStatus {
  UNVERIFIED = 'UNVERIFIED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  REJECTED = 'REJECTED',
  VERIFIED = 'VERIFIED',
}

export const VerificationStatusName = {
  [VerificationStatus.PENDING_REVIEW]: 'Pending review',
  [VerificationStatus.REJECTED]: 'Rejected',
  [VerificationStatus.UNVERIFIED]: 'Unverified',
  [VerificationStatus.VERIFIED]: 'Verified',
};
