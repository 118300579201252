import { API, graphqlOperation } from 'aws-amplify';
import { getUserLogsQuery } from './query';
import { LogEntry } from '../domain/logEntry';

export async function getUserLogs(
  userId: string,
  dateRangeStart: Date,
  dateRangeEnd: Date
): Promise<LogEntry[]> {
  const fromDate = dateRangeStart.toISOString();
  const toDate = dateRangeEnd.toISOString();
  const result = await API.graphql(
    graphqlOperation(getUserLogsQuery, {
      userId,
      fromDate,
      toDate,
    })
  );
  return result.data.getUserLogs;
}
