import React, { useEffect, useState } from 'react';
import { ReviewsTab } from './ReviewsTab';
import { BanksTab } from './BanksTab';
import { HistoryTab } from './HistoryTab';
import { ProfileTab } from './ProfileTab';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import Spinner from '@atlaskit/spinner';
import EmailIcon from '@atlaskit/icon/glyph/email';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { useQuery } from '@apollo/client';
import { Action } from '../../../domain/actions';
import {
  FlexCenter,
  PanelCloseBtn,
  SidePanelContainer,
  SidePanelTabs,
  UserBlock,
  UserBlockEmail,
  UserBlockText,
  UserBlockTitle,
} from './SidePanel.elements';
import { getKVKProvidedData, getUserWithDocuments } from '../../../data/query';
import { KYCState } from '../../../data/getUser';
import { User } from '../../../domain/user';
import { VerificationDocument } from '../../../domain/verificationDocument';
import { IDINUserInfo } from '../../../domain/signicat';
import { FlowsTab } from './FlowsTab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LogsTab } from './LogsTab';
import { styled } from 'styled-components';
import { AccountsTab } from './AccountsTab';

export interface KvkData {
  tradeNames: {
    businessName: string;
  };
  businessActivities: {
    sbiCode?: string;
    sbiCodeDescription?: string;
    isMainSbi?: boolean;
  }[];
  websites: string[];
}

export interface UserTypes {
  getUser: {
    user: User;
    kycState: KYCState;
  };
  getVerificationDocuments: VerificationDocument[];
  getIDINProvidedData: IDINUserInfo | null;
}
export interface KVKProvidedData {
  getKVKProvidedData: KvkData | null;
}

export function useKVKData(variables: { userId: string; bankAccountId?: string }) {
  const { data } = useQuery<KVKProvidedData>(getKVKProvidedData, {
    variables,
    skip: !variables.bankAccountId,
  });
  return data?.getKVKProvidedData;
}

const Panel = styled.div`
  width: 100%;
  margin-top: 1em;
`;

const SidePanel: React.FC<{ id?: string }> = ({ id }) => {
  const { id: paramsId } = useParams();
  const userId = id ?? paramsId;
  const [actions, setActions] = useState<Action[]>([]);
  const [name, setName] = useState<string>('');

  if (!userId) return null;

  const { data, loading, error } = useQuery<UserTypes, { userId: string }>(getUserWithDocuments, {
    variables: { userId },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const root = location.pathname.match(/\/[a-z-]+\//g);

  useEffect(() => {
    if (data) {
      setName(`${data.getUser.user.given_name} ${data.getUser.user.family_name}`);
      setActions(data.getUser.kycState.actions.map((el) => JSON.parse(el)));
    }
  }, [data]);

  if (loading) {
    return (
      <SidePanelContainer>
        <PanelCloseBtn onClick={() => navigate(root ? root[0] : '/')}>
          <CrossIcon label="close" size="medium" />
        </PanelCloseBtn>

        <FlexCenter>
          <Spinner size="medium" />
        </FlexCenter>
      </SidePanelContainer>
    );
  }

  if (error && !error.message.includes('not found in Cognito')) {
    console.log(error);
    return (
      <SidePanelContainer>
        <PanelCloseBtn onClick={() => navigate(root ? root[0] : '/')}>
          <CrossIcon label="close" size="medium" />
        </PanelCloseBtn>

        <FlexCenter>
          <div>{error?.message || 'Fetching error'}</div>
        </FlexCenter>
      </SidePanelContainer>
    );
  }

  return (
    <SidePanelContainer>
      <UserBlock>
        <UserBlockTitle>{name}</UserBlockTitle>
        {data && (
          <UserBlockEmail href={`mailto:${data.getUser.user.email}`}>
            <EmailIcon label="email" size="small" />
            <UserBlockText>{data.getUser.user.email}</UserBlockText>
          </UserBlockEmail>
        )}
      </UserBlock>
      <PanelCloseBtn onClick={() => navigate(root ? root[0] : '/')}>
        <CrossIcon label="close" size="medium" />
      </PanelCloseBtn>
      <Tabs id="default">
        <SidePanelTabs>
          <TabList>
            <Tab>Reviews</Tab>
            <Tab>Profile</Tab>
            <Tab>Banks</Tab>
            <Tab>Accounts</Tab>
            <Tab>History</Tab>
            <Tab>Logs</Tab>
            <Tab>Flows</Tab>
          </TabList>
        </SidePanelTabs>
        <TabPanel>
          <Panel>{data && <ReviewsTab data={data} />}</Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            {data && (
              <ProfileTab
                kycState={data.getUser.kycState}
                IDINUserInfo={data.getIDINProvidedData}
                user={data.getUser.user}
                documents={data.getVerificationDocuments}
              />
            )}
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            {data && <BanksTab bankAccounts={data.getUser.kycState.bankAccounts} userId={userId} />}
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            <AccountsTab userId={userId} />
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            {data && (
              <HistoryTab
                userId={userId}
                actions={actions}
                name={name}
                verificationDocuments={data.getVerificationDocuments}
                IDINInfo={data.getIDINProvidedData}
                answers={data.getUser.kycState.naturalPerson.answers}
                bankAccounts={data.getUser.kycState.bankAccounts}
              />
            )}
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            <LogsTab userId={userId} />
          </Panel>
        </TabPanel>
        <TabPanel>
          <Panel>
            <FlowsTab userId={userId} />
          </Panel>
        </TabPanel>
      </Tabs>
    </SidePanelContainer>
  );
};

export default React.memo(SidePanel);
