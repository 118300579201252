import { CardSection, SectionTitle } from './styles';
import React from 'react';

interface TransactionDescriptionProps {
  transaction: {
    description?: string;
  };
}

export function TransactionDescription({ transaction }: TransactionDescriptionProps) {
  if (!('description' in transaction) || !transaction.description) {
    return null;
  }

  return (
    <CardSection>
      <SectionTitle>Description</SectionTitle>
      <div>{transaction.description}</div>
    </CardSection>
  );
}
