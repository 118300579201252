import styled from 'styled-components';

export const SidePanelContainer = styled.aside`
  font-family: 'Basier Circle';
  /* padding: 24px; */
  height: 100vh;
  min-width: 370px;
  max-width: 600px;
  width: 500px;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #eeeeee;
  box-shadow: 0 0 600px rgba(0, 0, 0, 0.8);

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const UserBlock = styled.div`
  padding: 12px 24px;
  min-height: 80px;

  @media screen and (max-width: 768px) {
    padding: 0;
    min-height: 55px;
  }
`;

export const SidePanelTabs = styled.div`
  padding: 0 24px;
`;

export const UserBlockTitle = styled.h2`
  font-family: inherit;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
`;

export const UserBlockEmail = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1890ff;
  font-size: 12px;
  margin-top: 3px;
  &:active {
    outline: none;
    border: none;
  }
`;

export const UserBlockText = styled.div`
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  color: inherit;
  margin-left: 5px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

export const PanelCardContainer = styled.div`
  font-family: 'Basier Circle';
  max-width: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

  & + & {
    margin-top: 25px;
  }
`;

export const PanelCardHeader = styled.div`
  font-family: 'Basier Circle';
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PanelCardTitle = styled.h3`
  font-family: 'Basier Circle';
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const PanelCardDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #dbdbdb;
`;

export const PanelCardItem = styled.div`
  & + & {
    margin-top: 15px;
  }

  & + ${PanelCardDate} {
    margin-top: 15px;
  }
`;

export const PanelCardItemName = styled.div`
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
`;

export const PanelCardItemWarn = styled.div`
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: #ee7600;

  &:before {
    content: '⚠️ ';
  }

  ${PanelCardHeader} + & {
    margin-top: -25px;
    margin-bottom: 25px;
  }
`;

interface PanelCardItemValueProps {
  isRed?: boolean;
}

export const PanelCardItemValue = styled.div<PanelCardItemValueProps>`
  font-size: 14px;
  color: ${({ isRed = false }: PanelCardItemValueProps) => (isRed ? '#FF0000' : '#000')};
`;

export const PanelCardItemLink = styled.a`
  font-family: inherit;
  font-weight: 400;
  display: block;
  font-size: 14px;
  color: #1890ff;
`;

export const PanelCardSeparator = styled.div`
  padding: 20px 0;
  position: relative;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #bfbfbf;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
  }
`;

export const PanelCardControls = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
`;

interface PanelCardButtonProps {
  isPrimary?: boolean;
}

export const PanelCardButton = styled.button<PanelCardButtonProps>`
  font-family: inherit;
  border: none;
  outline: none;
  padding: 0 35px;
  font-size: 14px;
  line-height: 45px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-radius: 9px;
  background-color: ${(props: PanelCardButtonProps) => (props.isPrimary ? '#1890FF' : '#FF0000')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`;

export const TabContentWrapper = styled.div`
  height: calc(100vh - 160px);
  overflow-y: scroll;
  padding-bottom: 60px;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 768px) {
    height: calc(100vh - 136px);
  }

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const AllDone = styled.div`
  * + & {
    display: none;
  }
  font-size: 1.3rem;
  text-align: center;
  padding-top: 60px;
`;

export const InfoBlock = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export const InfoBlockTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
`;

export const Item = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const ItemName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: black;
`;

interface ItemValueProps {
  capitalize?: boolean;
}

export const ItemValue = styled.div<ItemValueProps>`
  font-size: 14px;
  color: black;
  text-transform: ${(props: ItemValueProps) => (props.capitalize ? 'lowercase' : 'none')};

  &::first-letter {
    text-transform: ${(props: ItemValueProps) => (props.capitalize ? 'capitalize' : 'none')};
  }
`;

export const ButtonDanger = styled.button`
  font-family: inherit;
  border: none;
  outline: none;
  padding: 0 35px;
  font-size: 14px;
  line-height: 45px;
  font-weight: 500;
  color: white;
  text-align: center;
  border-radius: 9px;
  background-color: #ff0000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`;

export const PanelCloseBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
