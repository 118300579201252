import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import {
  ReviewResult,
  NaturalPersonReviewAction,
  BankAccountReviewAction,
} from '../domain/actions';

export const naturalPersonReviewMutation = gql`
  mutation SubmitNaturalPersonReviewAction(
    $userId: String!
    $actionType: String!
    $reviewResult: String!
    $note: String!
  ) {
    submitNaturalPersonReview(
      userId: $userId
      actionType: $actionType
      reviewResult: $reviewResult
      note: $note
    ) {
      success
    }
  }
`;

export async function submitNaturalPersonReview(
  userId: string,
  actionType: NaturalPersonReviewAction['type'],
  reviewResult: ReviewResult,
  note: string
) {
  const result = await API.graphql(
    graphqlOperation(naturalPersonReviewMutation, {
      userId,
      actionType,
      reviewResult,
      note,
    })
  );

  return result;
}

export const bankAccountReviewMutation = gql`
  mutation SubmitBankAccountReviewAction(
    $userId: String!
    $bankAccountId: String!
    $actionType: String!
    $reviewResult: String!
    $note: String!
  ) {
    submitBankAccountReview(
      userId: $userId
      bankAccountId: $bankAccountId
      actionType: $actionType
      reviewResult: $reviewResult
      note: $note
    ) {
      success
    }
  }
`;

export async function submitBankAccountReview(
  userId: string,
  bankAccountId: string,
  actionType: BankAccountReviewAction['type'],
  reviewResult: ReviewResult,
  note: string
) {
  const result = await API.graphql(
    graphqlOperation(bankAccountReviewMutation, {
      userId,
      bankAccountId,
      actionType,
      reviewResult,
      note,
    })
  );

  return result;
}

export type HighRiskTransactionsReviewStatus =
  | 'UNREVIEWED'
  | 'REVIEWING'
  | 'BGTO_INVESTIGATING'
  | 'FALSE_POSITIVE'
  | 'REPORTED';

export const highRiskTransactionsReviewMutation = gql`
  mutation reviewTransaction(
    $year: Int!
    $month: Int!
    $id: String!
    $status: HighRiskTransactionReviewStatus!
    $note: String!
  ) {
    reviewTransaction(
      year: $year
      month: $month
      id: $id
      status: $status
      note: $note
    ) {
      success
    }
  }
`;
