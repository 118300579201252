import styled from 'styled-components';

interface FlexDivProps {
  flex?: number;
  direction?: string;
  align?: string;
  justify?: string;
  containerHeight?: string;
}

export const FlexDiv = styled.div<FlexDivProps>`
  display: flex;
  flex: ${(p: FlexDivProps) => p.flex || 1};
  align-items: ${(p: FlexDivProps) => p.align || 'flex-start'};
  justify-content: ${(p: FlexDivProps) => p.justify || 'flex-start'};
  flex-direction: ${(p: FlexDivProps) => p.direction || 'column'};
`;

export const MainContentWrapper = styled.main`
  padding: 20px;
  flex: 1;
  /* position: relative;
  top: 0;
  right: 0;
  left: 240px;
  width: calc(100vw - 260px); */
  overflow-y: scroll;
`;

export const TextCenter = styled.div`
  text-align: center;
`;
