import React, { useState } from 'react';
import { styled } from 'styled-components';
import Card from '../shared/general/Card';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ChevronButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
`;

const ClickableArea = styled.div`
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: ' ';
`;

interface CollapsibleTransactionCardProps {
  // amount: React.ReactNode;
  // status?: HighRiskTransactionsReviewStatus;
  children: React.ReactNode;
  collapsed?: boolean;
  alwaysVisibleContent?: React.ReactNode;
}

export function CollapsibleTransactionCard({
  collapsed,
  children,
  alwaysVisibleContent,
}: CollapsibleTransactionCardProps) {
  const [userCollapsed, setUserCollapsed] = useState<boolean | undefined>(undefined);
  const isCollapsed =
    // If userCollapsed is explicitly set to true or false, use that.
    typeof userCollapsed === 'boolean'
      ? userCollapsed
      : // Otherwise, if collapsed is explicitly set to true or false, use that.
      typeof collapsed === 'boolean'
      ? collapsed
      : // Otherwise, default to expanded.
        false;

  return (
    <Card>
      <CardHeader>
        <ChevronButton>
          {isCollapsed ? <ChevronRightIcon label="Collapse" /> : <ChevronDownIcon label="Expand" />}
        </ChevronButton>
        <div style={{ zIndex: 1, display: 'flex', width: '100%' }}>{alwaysVisibleContent}</div>
        <ClickableArea onClick={() => setUserCollapsed(!isCollapsed)}></ClickableArea>
        {/* <div>{amount}</div> */}
        {/* {status && status !== 'UNREVIEWED' && <span>{status === 'INVESTIGATING' ? 'Investigating' : status === 'FALSE_POSITIVE' ? 'False positive' : 'Reported'} <CheckIcon label=''/></span>} */}
      </CardHeader>
      {!isCollapsed && <div style={{ paddingTop: 16 }}>{children}</div>}
    </Card>
  );
}
