import React from 'react';
import { CardSection, SectionTitle, InfoGrid, Label } from './styles';
import { useNavigate } from 'react-router-dom';
import Button from '@atlaskit/button';

interface CounterPartyProps {
  counterParty: {
    accountHolderName?: string;
    countryOfAccount?: string;
    iban?: string;
    knownConnectedFlowUsers?: string[];
  };
}

export const CounterPartyDetails: React.FC<CounterPartyProps> = ({ counterParty }) => {
  const navigate = useNavigate();
  return (
    <CardSection>
      <SectionTitle>Counterparty Details</SectionTitle>
      <InfoGrid>
        <Label>Name:</Label>
        <div>
          {'accountHolderName' in counterParty ? counterParty.accountHolderName : 'Unknown'}
        </div>
        <Label>Connected users:</Label>
        <div>
          {counterParty?.knownConnectedFlowUsers?.map((u) => (
            <Button key={u} onClick={() => navigate(u)}>
              View
            </Button>
          )) ?? 'Unknown'}
        </div>
        <Label>Country:</Label>
        <div>{'countryOfAccount' in counterParty ? counterParty.countryOfAccount : 'Unknown'}</div>
        <Label>IBAN:</Label>
        <div>{'iban' in counterParty ? counterParty.iban : 'Unknown'}</div>
      </InfoGrid>
    </CardSection>
  );
};
