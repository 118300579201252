import React from 'react';
import {
  PanelCardButton,
  PanelCardContainer,
  PanelCardControls,
  PanelCardHeader,
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardTitle,
} from '../../SidePanel.elements';
import { PanelCardStandardProps } from '../types';
import { OptionsPropType } from '@atlaskit/radio/types';
import { ReviewModal } from '..';
import { useReviewCardState } from './hooks';
import { DefaultNotes } from './defaultNotes';
import { IDINUserInfo, TrapetsResult } from '../../../../../domain/signicat';
import { NaturalPersonRejectReason } from '../../../../../domain/naturalPerson';
import ReactJson from 'react-json-view';

interface ReviewPEPHitProps extends PanelCardStandardProps {
  IDINUserInfo: IDINUserInfo | null;
  idName: boolean | string;
  idBirthDate: boolean | string;
  rejectReason: NaturalPersonRejectReason;
  trapetsResult: TrapetsResult | null;
}

const ReviewPEP: React.FC<ReviewPEPHitProps> = ({ name, userId, review, rejectReason, trapetsResult, idName, idBirthDate, IDINUserInfo }) => {
  const {
    modalVisible,
    handleCancel,
    handleOk,
    modalTitle,
    note,
    setNote,
    value,
    setValue,
    onClickHandler,
  } = useReviewCardState({
    userId,
    actionType: 'REVIEW_PEP_HIT',
    defaultNote: DefaultNotes.ReviewPEPHit,
    review,
  });

  const options: OptionsPropType = [
    { name: 'name', value: '1', label: 'False-positive PEP name match' },
    { name: 'other', value: '2', label: 'Other (O)' },
  ];

  if (rejectReason !== 'PERSON_IS_PEP') return null;

  return (
    <>
      <PanelCardContainer>
        <PanelCardHeader>
          <PanelCardTitle>Review PEP Hit</PanelCardTitle>
        </PanelCardHeader>
        <PanelCardItem>
          <PanelCardItemName>Flow Name</PanelCardItemName>
          <PanelCardItemValue>{name}</PanelCardItemValue>
        </PanelCardItem>
        {IDINUserInfo ? <>
          <PanelCardItem>
            <PanelCardItemName>iDIN Name</PanelCardItemName>
            <PanelCardItemValue>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>iDIN Given Name</PanelCardItemName>
            <PanelCardItemValue>{IDINUserInfo?.given_name || '-'}</PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>iDIN Family Name</PanelCardItemName>
            <PanelCardItemValue>{IDINUserInfo?.family_name || '-'}</PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>iDIN Birthdate</PanelCardItemName>
            <PanelCardItemValue>{IDINUserInfo?.birthdate || '-'}</PanelCardItemValue>
          </PanelCardItem>
        </> : <>
          <PanelCardItem>
            <PanelCardItemName>Name</PanelCardItemName>
            <PanelCardItemValue>
              {typeof idName === 'boolean' ? '-' : idName}
            </PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>Date of Birth</PanelCardItemName>
            <PanelCardItemValue>
              {typeof idBirthDate === 'boolean' ? '-' : idBirthDate}
            </PanelCardItemValue>
          </PanelCardItem>
        </>
        }
        <PanelCardSeparator />
        <PanelCardItem>
          <PanelCardItemName>PEP data from Signicat</PanelCardItemName>
          <ReactJson
              src={trapetsResult?.pepHits ?? {}}
              name="trapetsResult.pepHits"
              theme="shapeshifter:inverted"
              displayDataTypes={false}
              collapsed={false}
              indentWidth={2}
              style={{ marginTop: '20px' }}
            />
        </PanelCardItem>
        <PanelCardControls>
          <PanelCardButton onClick={() => onClickHandler('APPROVE')} isPrimary>Not PEP</PanelCardButton>
          <PanelCardButton onClick={() => onClickHandler('REJECT')}>Reject</PanelCardButton>
        </PanelCardControls>
      </PanelCardContainer>

      <ReviewModal
        modalVisible={modalVisible}
        modalTitle={modalTitle}
        handleCancel={handleCancel}
        handleAccept={handleOk}
        options={options}
        optionValue={value.toString()}
        setOptionValue={setValue}
        note={note}
        setNoteValue={setNote}
      />
    </>
  );
};

export default ReviewPEP;
