import { UBOTrapetsResults } from './bankAccount';
import { IDINUserInfo, TrapetsResult } from './signicat';
import { KVKBasisprofiel, OldKVKCompanyExtendedV2 } from './kvk';
import { UserName } from './user';

export type Action =
  | RequestAddBankAction
  | RequestIDVerificationAction
  | BankAccountAction
  | NaturalPersonAction;

export type BankAccountAction =
  | ProvideBusinessNameAction
  | ProvideKVKNumberAction
  | ProvideBusinessAccountConfirmationAction
  | ProvideKVKCompanyDataAction
  | ProvideBusinessOwnerConfirmationAction
  | ProvideKVKUitrekselAction
  | ProvideOnlyUBOConfirmationAction
  | ProvideUBOAction
  | RemoveUBOAction
  | ProvideUBOCompleteConfirmationAction
  | ProvideTrapetsUBOAction
  | BankAccountReviewAction;

export type BankAccountReviewAction =
  | ReviewBankAccountHolderNamesAction
  | ReviewSupportOverrideAction
  | ReviewRepresentativeAction
  | ReviewUBOVerificationAction;

export type NaturalPersonAction =
  | ProvideUserNameAction
  | ProvideIDINUserInfoAction
  | ProvideIDDocumentAction
  | ProvideTrapetsAction
  | NaturalPersonReviewAction;

export type NaturalPersonReviewAction = 
  | ReviewUserNameAction
  | ReviewIDDocumentAction
  | ReviewPEPHitAction
  | ReviewSanctionHitAction;

export interface RequestAddBankAction {
  type: 'REQUEST_ADD_BANK';
  // provided by the user during registration
  userName: {
    given_name: string;
    family_name: string;
  };
  // provided by the bank
  bankAccountId: string;
  bankAccountHolderNames: string[];
  jointHolderNames?: string[];
  isBusinessAccount: boolean;
}

export interface RequestIDVerificationAction {
  type: 'REQUEST_ID_VERIFICATION';
  // provided by the user during registration
  userName: {
    given_name: string;
    family_name: string;
  };
}

export interface ProvideBusinessNameAction {
  type: 'PROVIDE_BUSINESS_NAME';
  bankAccountId: string;
  businessName: string;
}

export interface ProvideKVKNumberAction {
  type: 'PROVIDE_KVK_NUMBER';
  bankAccountId: string;
  kvkNumber: string;
}

export interface ProvideBusinessAccountConfirmationAction {
  type: 'PROVIDE_BUSINESS_ACCOUNT_CONFIRMATION';
  bankAccountId: string;
  isBusinessAccount: boolean;
}

export interface ProvideKVKCompanyDataAction {
  type: 'PROVIDE_KVK_COMPANY_DATA';
  bankAccountId: string;
  kvkCompany: KVKBasisprofiel | OldKVKCompanyExtendedV2;
}

export interface ProvideBusinessOwnerConfirmationAction {
  type: 'PROVIDE_BUSINESS_OWNER_CONFIRMATION';
  bankAccountId: string;
  isBusinessOwner: boolean;
}

export interface ProvideKVKUitrekselAction {
  type: 'PROVIDE_KVK_UITREKSEL';
  bankAccountId: string;
  kvkUitrekselDocumentFile: string;
}

export interface ProvideOnlyUBOConfirmationAction {
  type: 'PROVIDE_ONLY_UBO_CONFIRMATION';
  bankAccountId: string;
  isOnlyUBO: boolean;
}

export interface ProvideUBOAction {
  type: 'PROVIDE_UBO';
  bankAccountId: string;
  uboId: string;
  name: string;
  birthDate: string;
  idDocumentFile: string;
}

export interface RemoveUBOAction {
  type: 'REMOVE_UBO';
  bankAccountId: string;
  uboId: string;
}

export interface ProvideUBOCompleteConfirmationAction {
  type: 'PROVIDE_UBO_COMPLETE_CONFIRMATION';
  bankAccountId: string;
  isComplete: boolean;
}

export interface ProvideTrapetsUBOAction {
  type: 'PROVIDE_TRAPETS_UBO';
  bankAccountId: string;
  uboTrapetsResults: UBOTrapetsResults;
}

export interface ProvideUserNameAction {
  type: 'PROVIDE_USER_NAME';
  userName: UserName;
}

export interface ProvideIDINUserInfoAction {
  type: 'PROVIDE_IDIN_USER_INFO';
  idinUser: IDINUserInfo;
}

export interface ProvideIDDocumentAction {
  type: 'PROVIDE_ID_DOCUMENT';
  idName: string;
  idBirthDate: string;
  idDocumentFile: string;
}

export interface ProvideTrapetsAction {
  type: 'PROVIDE_TRAPETS';
  trapetsResult: TrapetsResult;
}

interface ReviewAction {
  reviewByCRMUser: {
    id: string;
    email: string;
  };
  note: string;
  reviewResult: ReviewResult;
}

export interface ReviewBankAccountHolderNamesAction extends ReviewAction {
  type: 'REVIEW_BANK_ACCOUNT_HOLDER_NAMES';
  bankAccountId: string;
}

export interface ReviewSupportOverrideAction extends ReviewAction {
  type: 'REVIEW_SUPPORT_OVERRIDE';
  bankAccountId: string;
}

export interface ReviewRepresentativeAction extends ReviewAction {
  type: 'REVIEW_REPRESENTATIVE';
  bankAccountId: string;
}

export interface ReviewUBOVerificationAction extends ReviewAction {
  type: 'REVIEW_UBO_VERIFICATION';
  bankAccountId: string;
}

export interface ReviewUserNameAction extends ReviewAction {
  type: 'REVIEW_USER_NAME';
}

export interface ReviewIDDocumentAction extends ReviewAction {
  type: 'REVIEW_ID_VERIFICATION';
}

export interface ReviewPEPHitAction extends ReviewAction {
  type: 'REVIEW_PEP_HIT';
}

export interface ReviewSanctionHitAction extends ReviewAction {
  type: 'REVIEW_SANCTION_HIT';
}

export enum ReviewResult {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export function isManualReviewAction(action: Action) {
  return 'reviewByCRMUser' in action;
}
