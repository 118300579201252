import React from 'react';
import { CardSection, SectionTitle, InfoGrid, Label } from './styles';
import { MonetaryTransactionInfo } from '../../data/getHighRiskTransactions';

interface TimelineProps {
  transaction: Pick<MonetaryTransactionInfo, 'occurred' | 'settled' | 'kind'>;
}

export function Timeline({ transaction }: TimelineProps) {
  return (
    <CardSection>
      <SectionTitle>Timeline</SectionTitle>
      <InfoGrid>
        <Label>Occurred:</Label>
        <div>{'occurred' in transaction ? transaction.occurred : 'Unknown'}</div>
        <Label>Settled:</Label>
        <div>{'settled' in transaction ? transaction.settled : 'Unknown'}</div>
        <Label>Transaction kind:</Label>
        <div>{'kind' in transaction ? transaction.kind : 'Unknown'}</div>
      </InfoGrid>
    </CardSection>
  );
}
