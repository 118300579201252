import React from 'react';
import { styled } from 'styled-components';

const CardContainer = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px;
  background-color: white;
`;

interface IProps {
  children: React.ReactNode | JSX.Element | JSX.Element[];
}

const Card = ({ children }: IProps) => {
  return <CardContainer>{children}</CardContainer>;
};

export default Card;
