import React from 'react';
import { IDINUserInfo } from '../../../../domain/signicat';
import { useKVKData } from '../SidePanel';
import {
  PanelCardItem,
  PanelCardItemName,
  PanelCardItemValue,
  PanelCardSeparator,
  PanelCardItemLink,
} from '../SidePanel.elements';

interface ReviewAccountHoldersNameActionProps {
  userId: string,
  bankAccountId?: string,
  name: string;
  isBusinessAccount?: boolean | 'true' | 'false';
  IDINUserInfo: IDINUserInfo | null;
  bankAccountHolderNames: string[];
}

const ReviewAccountHoldersNameAction: React.FC<ReviewAccountHoldersNameActionProps> = (props) => {
  const {
    name,
    isBusinessAccount,
    IDINUserInfo,
    bankAccountHolderNames,
  } = props;
  const kvkInfo = useKVKData(props);
  
  return (
    <>
      <PanelCardItem>
        <PanelCardItemName>Flow Name</PanelCardItemName>
        <PanelCardItemValue>{name}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>iDin Name</PanelCardItemName>
        <PanelCardItemValue>{IDINUserInfo?.name || '-'}</PanelCardItemValue>
      </PanelCardItem>
      {kvkInfo && (
        <>
          <PanelCardSeparator />
          <PanelCardItem>
            <PanelCardItemName>KVK Business Name</PanelCardItemName>
            <PanelCardItemValue isRed>
              {kvkInfo?.tradeNames?.businessName || '-'}
            </PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>Description</PanelCardItemName>
            <PanelCardItemValue isRed>
              {kvkInfo?.businessActivities?.map(a => a.sbiCodeDescription).join(', ') || '-'}
            </PanelCardItemValue>
          </PanelCardItem>
          <PanelCardItem>
            <PanelCardItemName>Website</PanelCardItemName>
            <PanelCardItemValue>
              {kvkInfo?.websites?.map((site) => (
                <PanelCardItemLink href={`http://${site}`} target="_blank">
                  {site}
                </PanelCardItemLink>
              )) || '-'}
            </PanelCardItemValue>
          </PanelCardItem>
        </>
      )}
      <PanelCardSeparator />
      <PanelCardItem>
        <PanelCardItemName>Bank account holder name</PanelCardItemName>
        <PanelCardItemValue isRed>{bankAccountHolderNames.join() || '-'}</PanelCardItemValue>
      </PanelCardItem>
      <PanelCardItem>
        <PanelCardItemName>Bank account type</PanelCardItemName>
        <PanelCardItemValue>
          {isBusinessAccount === 'true' ? 'Business' : 'Personal'}
        </PanelCardItemValue>
      </PanelCardItem>
    </>
  );
};

export default ReviewAccountHoldersNameAction;
