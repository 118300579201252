import React from 'react';
import { App } from './components/App';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import '@atlaskit/css-reset';
import './theme/fonts.css';
import { client } from './data/client';

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  window.history.replaceState({}, '', path);
}

const AppRoot = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(AppRoot());

