import { NoneDataPoint, SomeDataPoint } from './dataPoint';
import { MaybeRequired, Required } from './required';

export type Answer<T> = NoAnswer | HasAnswer<T>;
export type NoAnswer = NoneDataPoint & MaybeRequired;
export type HasAnswer<T> = SomeDataPoint<T> & MaybeRequired;
export type RequiredAnswer<T> = Answer<T> & Required;

export function hasAnsweredWhatsRequired<T>(answer: Answer<T>) {
  return !answer.isRequired || answer.hasValue
}
